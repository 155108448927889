export default {
  baseApiUrl: "https://transact.tremblant.ca/igoreapi/api/igore",
  stubbedApiUrl: "/data",
  sessionId: "",
  locale: "en",
  status: "Loading Dates...",

  availableDates: null,
  availableTimes: null,
  selectedDate: "",
  selectedTime: "",
  quickSearchSelectedStartTime: "",
  quickSearchSelectedNoOfGolfers: "",
  quickSearchSelectedCourse: "",

  currentReservations: {},
  fetchingReservations: false,

  reservationDetails: {},
  reservationSelectedNumGolfers: "",
  reservationMemberCardType: "none",
  reservationFirstName: "",
  reservationLastName: "",
  cancelingReservation: null, // holds the booking being canceled
  confirmingReservation: false, // submit to backend happening
  reservationConfirmed: false,

  loginInProcess: false,
  userExistsForEmail: false,
  creatingAccountInProcess: false,
  userDetails: {
    loggedIn: false,
    userLoginErrorMsg: false,
    passwordResetInProcess: false,
    passwordResetSent: false,
    passwordResetFailed: false,
    passwordWasReset: false,
    firstName: "",
    lastName: "",
    email: "",
    passwordToken: "",
    password: "",
    passwordConfirmation: "",
    postalCode: "",
    address: "",
    address2: "",
    country: "",
    province: "",
    city: "",
    telephone: "",
    newsletterSignup: false,
  },
  checkoutDetails: {
    creditCardNumber: "",
    creditCardName: "",
    creditCardExpirationMonth: "",
    creditCardExpirationYear: "",
  },
};
