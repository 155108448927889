<template>
  <div>
    <b-form-group
      :label="$t('labels.password')"
      :label-for="passwordFieldID"
      label-cols-sm="4"
      label-size="sm"
      :invalid-feedback="invalidPassword"
      :state="passwordState"
    >
      <b-form-input
        size="sm"
        :id="passwordFieldID"
        v-model="password"
        :state="passwordState"
        autocomplete="current-password"
        type="password"
        required="required"
        trim
        debounce="1000"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="password-conf-fieldset"
      :label="$t('labels.confirmPassword')"
      label-for="passwordConfirmationAcct"
      label-cols-sm="4"
      label-size="sm"
      :invalid-feedback="invalidPasswordConf"
      :state="passwordConfState"
      v-if="showConfirmation"
    >
      <b-form-input
        size="sm"
        id="passwordConfirmationAcct"
        v-model="passwordConfirmation"
        :state="passwordConfState"
        required="required"
        autocomplete="current-password"
        type="password"
        trim
        debounce="1000"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  props: ["includeConfirmation", "formContext"],
  data() {
    return {};
  },
  computed: {
    passwordFieldID() {
      return `password-${this.formContext}`;
    },
    showConfirmation() {
      return this.includeConfirmation == "true";
    },
    passwordState() {
      if (this.password.length === 0) {
        return;
      }

      return this.password.length >= 4;
    },
    invalidPassword() {
      if (this.password.length === 0) {
        return "";
      }

      if (this.passwordState) {
        return "";
      } else {
        return this.$t("messages.atLeast4Characters");
      }
    },
    passwordConfState() {
      if (this.passwordConfirmation.length === 0) {
        return;
      }

      return (
        this.passwordConfirmation.length >= 4 &&
        this.password === this.passwordConfirmation
      );
    },
    invalidPasswordConf() {
      if (this.passwordConfirmation.length === 0) {
        return "";
      }

      if (this.passwordConfState) {
        return "";
      } else {
        if (this.passwordConfirmation.length >= 4) {
          return "Passwords must match!";
        } else {
          return this.$t("messages.atLeast4Characters");
        }
      }
    },
    password: {
      get: function () {
        return this.$store.state.userDetails.password;
      },
      set: function (password) {
        this.$store.commit("SET_USER_PASSWORD", password);
      },
    },
    passwordConfirmation: {
      get: function () {
        return this.$store.state.userDetails.passwordConfirmation;
      },
      set: function (passwordConfirmation) {
        this.$store.commit(
          "SET_USER_PASSWORD_CONFIRMATION",
          passwordConfirmation
        );
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
