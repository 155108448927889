<template>
  <div class="quick-search-datepicker row">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="card-title">{{ $t("titles.quickSearch") }}</h5>
        <form>
          <ul class="list-group list-group-flush">
            <li class="list-group-item w-100 mb-2">
              <b-form-datepicker
                id="datepicker-quick"
                v-model="selectedDate"
                size="sm"
                :placeholder="$t('labels.selectDate')"
                hide-header
                label-help=""
                :locale="locale"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                selected-variant="success"
                today-variant="info"
                nav-button-variant="primary"
                class="w-100"
                :initial-date="initialDate"
                :min="minDate"
                :max="maxDate"
                :date-info-fn="dateTextAndClass"
              ></b-form-datepicker>
            </li>

            <li class="list-group-item w-100">
              <div class="w-100">
                <b-form-group
                  :label="$t('labels.startsAt')"
                  label-cols="12"
                  label-size="sm"
                  input-cols="12"
                  label-for="inputStartsAt"
                >
                  <b-form-select
                    v-model="quickSearchSelectedStartTime"
                    :options="startTimeOptions"
                    id="inputStartsAt"
                    size="sm"
                    class="w-100"
                  ></b-form-select>
                </b-form-group>
              </div>
            </li>

            <li class="list-group-item w-100">
              <div class="w-100">
                <b-form-group
                  :label="$t('labels.numGolfers')"
                  label-cols="12"
                  label-size="sm"
                  input-cols="12"
                  size="sm"
                  label-for="numberOfGolfers"
                >
                  <b-form-select
                    v-model="noOfGolfers"
                    :options="numGolfersOptions"
                    id="numberOfGolfers"
                    size="sm"
                    class="w-100"
                  ></b-form-select>
                </b-form-group>
              </div>
            </li>

            <li class="list-group-item w-100">
              <div class="w-100 mb-3">
                <b-form-group
                  :label="$t('course')"
                  label-cols="12"
                  label-size="sm"
                  input-cols="12"
                  size="sm"
                  label-for="courseNames"
                >
                  <b-form-select
                    v-model="selectedCourse"
                    :options="courseOptions"
                    id="courseNames"
                    size="sm"
                    class="w-100"
                  ></b-form-select>
                </b-form-group>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickSearch",
  data() {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const initialMonth = currentMonth >= 4 ? currentMonth : 4; // Show May unless past May
    const initialDate = new Date(currentYear, initialMonth, 1);
    const minDate = new Date(initialDate);
    const maxDate = new Date(currentYear, 9, 31);

    const courseOptions = [
      { value: "", text: this.$t("selectCourse") },
      { value: "Golf Le Diable", text: "Golf Le Diable" },
      { value: "Golf Le Géant", text: "Golf Le Géant" },
    ];

    return {
      initialDate: initialDate,
      minDate: minDate,
      maxDate: maxDate,
      context: null,
      startTimeOptions: [
        { value: "", text: this.$t("selectTime") },
        { value: "06:00", text: "06:00" },
        { value: "06:10", text: "06:10" },
        { value: "06:20", text: "06:20" },
        { value: "06:30", text: "06:30" },
        { value: "06:40", text: "06:40" },
        { value: "06:50", text: "06:50" },
        { value: "07:00", text: "07:00" },
        { value: "07:10", text: "07:10" },
        { value: "07:20", text: "07:20" },
        { value: "07:30", text: "07:30" },
        { value: "07:40", text: "07:40" },
        { value: "07:50", text: "07:50" },
        { value: "08:00", text: "08:00" },
        { value: "08:10", text: "08:10" },
        { value: "08:20", text: "08:20" },
        { value: "08:30", text: "08:30" },
        { value: "08:40", text: "08:40" },
        { value: "08:50", text: "08:50" },
        { value: "09:00", text: "09:00" },
        { value: "09:10", text: "09:10" },
        { value: "09:20", text: "09:20" },
        { value: "09:30", text: "09:30" },
        { value: "09:40", text: "09:40" },
        { value: "09:50", text: "09:50" },
        { value: "10:00", text: "10:00" },
        { value: "10:10", text: "10:10" },
        { value: "10:20", text: "10:20" },
        { value: "10:30", text: "10:30" },
        { value: "10:40", text: "10:40" },
        { value: "10:50", text: "10:50" },
        { value: "11:00", text: "11:00" },
        { value: "11:10", text: "11:10" },
        { value: "11:20", text: "11:20" },
        { value: "11:30", text: "11:30" },
        { value: "11:40", text: "11:40" },
        { value: "11:50", text: "11:50" },
        { value: "12:00", text: "12:00" },
        { value: "12:10", text: "12:10" },
        { value: "12:20", text: "12:20" },
        { value: "12:30", text: "12:30" },
        { value: "12:40", text: "12:40" },
        { value: "12:50", text: "12:50" },
        { value: "13:00", text: "13:00" },
        { value: "13:10", text: "13:10" },
        { value: "13:20", text: "13:20" },
        { value: "13:30", text: "13:30" },
        { value: "13:40", text: "13:40" },
        { value: "13:50", text: "13:50" },
        { value: "14:00", text: "14:00" },
        { value: "14:10", text: "14:10" },
        { value: "14:20", text: "14:20" },
        { value: "14:30", text: "14:30" },
        { value: "14:40", text: "14:40" },
        { value: "14:50", text: "14:50" },
        { value: "15:00", text: "15:00" },
        { value: "15:10", text: "15:10" },
        { value: "15:20", text: "15:20" },
        { value: "15:30", text: "15:30" },
        { value: "15:40", text: "15:40" },
        { value: "15:50", text: "15:50" },
        { value: "16:00", text: "16:00" },
        { value: "16:10", text: "16:10" },
        { value: "16:20", text: "16:20" },
        { value: "16:30", text: "16:30" },
        { value: "16:40", text: "16:40" },
        { value: "16:50", text: "16:50" },
        { value: "17:00", text: "17:00" },
        { value: "17:00", text: "17:00" },
        { value: "17:10", text: "17:10" },
        { value: "17:20", text: "17:20" },
        { value: "17:30", text: "17:30" },
        { value: "17:40", text: "17:40" },
        { value: "17:50", text: "17:50" },
      ],
      numGolfersOptions: [
        { value: "", text: this.$t("selectNum") },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
      ],
      courseOptions: courseOptions,
    };
  },
  computed: {
    locale() {
      if (this.$i18n.locale == "fr") {
        return "fr-CA";
      } else {
        return "en-US";
      }
    },
    selectedDate: {
      get: function () {
        return this.$store.state.selectedDate;
      },
      set: function (date) {
        this.$store.commit("SET_SELECTED_DATE", date);
      },
    },
    quickSearchSelectedStartTime: {
      get: function () {
        return this.$store.state.quickSearchSelectedStartTime;
      },
      set: function (time) {
        this.$store.commit("SET_SELECTED_START_TIME", time);
      },
    },
    noOfGolfers: {
      get: function () {
        return this.$store.state.quickSearchSelectedNoOfGolfers;
      },
      set: function (num) {
        this.$store.commit("SET_SELECTED_NUM_GOLFERS", num);
      },
    },
    selectedCourse: {
      get: function () {
        return this.$store.state.quickSearchSelectedCourse;
      },
      set: function (course) {
        this.$store.commit("SET_SELECTED_COURSE", course);
      },
    },
  },
  methods: {
    dateTextAndClass(ymd, date) {
      // Return classes for #s left
      // Return an disabled class if none left / available.
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#igore-golf-app {
  .quick-search-datepicker {
    margin-top: 6px; // Position next to top of calendar
    padding: 0 15px;
    @media (min-width: 768px) {
      padding: 0 0;
    }
    .card-body {
      padding: 0.5rem 0 0;
    }
    .card-title {
      padding: 0 0.5rem;
    }
    .list-group-flush {
      padding: 0 0.5rem;
      > .list-group-item {
        border-width: 0;
        padding: 0.25rem 0 0 0 !important;
        .form-group {
          margin-bottom: 0;
          label {
            padding-bottom: 0;
          }
        }
      }
    }
    .b-calendar {
      .b-calendar-nav {
        // embed page css creates the need for this due to 92px margin w/ high specificity
        margin-top: 0 !important;
        .btn.disabled:first-child,
        .btn.disabled:last-child {
          display: none;
        }
      }
      .b-calendar-grid-caption {
        color: #2c444d;
        font-family: "Stag";
        font-size: 1.1rem;
      }
      .b-calendar-grid-weekdays {
        // embed page css creates the need for this due to 92px margin w/ high specificity
        margin-top: 0 !important;
        small {
          font-size: 12.5px !important;
        }
      }
      .b-calendar-grid-body {
        .col[data-date] {
          span.btn {
            &:focus,
            &.focus {
              box-shadow: 0 0 0 0.1rem #9ab1ba;
            }
            &:hover {
              background-color: #9ab1ba;
              color: #fff;
            }
            &.active {
              background-color: #2c444d;
              &:after {
                color: #fff;
              }
              .date-circle {
                background-color: #ab192d;
              }
            }
            &.btn-outline-info {
              color: #ab192d;
            }
          }
        }
      }
    }
  }
}
</style>
