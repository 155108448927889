<template>
  <div id="igore-golf-app" :class="classLocale">
    <div class="container mw-100">
      <div class="row">
        <b-tabs
          nav-wrapper-class="w-100 mt-2 d-sm-block d-md-none pl-3 pr-3"
          nav-class="mx-auto"
          fill
          pills
          justified
        >
          <b-tab
            :title="$t('titles.quickSearch')"
            @click="setQuickSearchActive"
            active
          ></b-tab>
          <b-tab
            :title="$t('titles.calendar')"
            @click="setCalendarActive"
          ></b-tab>
        </b-tabs>
        <div
          class="col-sm-12 col-lg-2 mt-4 pt-1 order-lg-1 order-md-0 order-sm-0"
        >
          <QuickSearch :class="quickSearchMobileClass" />
        </div>
        <div class="col-lg-5 col-sm-12 order-lg-0 order-md-1 order-sm-1">
          <b-overlay
            v-if="!availableDates"
            :show="!availableDates"
            opacity="0.6"
            spinner-type="border"
            spinner-variant="primary"
            class="d-inline-block w-100 mt-5 pt-5"
          ></b-overlay>
          <Calendar
            :available-dates="availableDates"
            v-if="availableDates"
            :class="calendarMobileClass"
          />

          <b-overlay
            v-if="selectedDate && !availableTimes"
            :show="selectedDate && !availableTimes"
            opacity="0.6"
            spinner-type="border"
            spinner-variant="primary"
            class="d-inline-block w-100 mt-5 pt-5"
          ></b-overlay>
          <AvailableTimes
            :available-times="availableTimes"
            v-if="availableTimes"
          />
        </div>

        <div class="col-sm-12 col-lg-4 offset-lg-1 order-sm-2">
          <ReservationDetails
            :selected-date="selectedDate"
            :selected-time="selectedTime"
            :selected-num-golfers="selectedNumGolfers"
            :userLoggedIn="userLoggedIn"
          />
          <Account
            :userLoggedIn="userLoggedIn"
            :userEmail="userEmail"
            :userFirstName="userFirstName"
            :userLastName="userLastName"
          />
          <Checkout
            :selected-date="selectedDate"
            :selected-time="selectedTime"
            :userLoggedIn="userLoggedIn"
          />
          <CurrentReservations :userLoggedIn="userLoggedIn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "./components/Calendar.vue";
import AvailableTimes from "./components/AvailableTimes.vue";
import QuickSearch from "./components/QuickSearch.vue";
import ReservationDetails from "./components/ReservationDetails.vue";
import Account from "./components/Account.vue";
import Checkout from "./components/Checkout.vue";
import CurrentReservations from "./components/CurrentReservations.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TremblantGolf",
  components: {
    Account,
    AvailableTimes,
    Calendar,
    Checkout,
    CurrentReservations,
    QuickSearch,
    ReservationDetails,
  },
  data() {
    return {
      quickSearchActive: true,
      calendarActive: false,
    };
  },
  mounted() {
    this.getAvailableDates();
    this.checkForExistingSession();
  },
  watch: {
    selectedDate() {
      this.getAvailableTimes();
    },
  },
  computed: {
    ...mapGetters([
      "availableDates",
      "availableTimes",
      "selectedDate",
      "selectedTime",
      "selectedNumGolfers",
      "sessionId",
      "userEmail",
      "userFirstName",
      "userLastName",
      "userLoggedIn",
    ]),
    classLocale() {
      return `${this.$i18n.locale}-locale`;
    },
    quickSearchMobileClass() {
      if (this.quickSearchActive) {
        return "d-sm-block";
      } else {
        return "d-none d-md-block";
      }
    },
    calendarMobileClass() {
      if (this.calendarActive) {
        return "d-sm-block";
      } else {
        return "d-none d-md-block";
      }
    },
  },
  methods: {
    ...mapActions([
      "getAvailableDates",
      "getAvailableTimes",
      "checkForExistingSession",
    ]),
    setQuickSearchActive() {
      this.quickSearchActive = true;
      this.calendarActive = false;
    },
    setCalendarActive() {
      this.quickSearchActive = false;
      this.calendarActive = true;
    },
  },
};
</script>

<style lang="scss"></style>
