<template>
  <div>
    <b-form-group
      :label="$t('labels.email')"
      :label-for="emailID"
      label-cols-sm="4"
      label-size="sm"
      :invalid-feedback="invalidEmailFeedback"
      :state="emailState"
    >
      <b-form-input
        size="sm"
        :id="emailID"
        v-model="email"
        :state="emailState"
        autocomplete="username"
        type="email"
        trim
        debounce="1250"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "EmailInput",
  props: ["formContext"],
  computed: {
    emailID() {
      return `email-${this.formContext}`;
    },
    emailState() {
      if (this.email.length === 0) {
        return;
      }

      return /^\S+@\S+\.\S+$/.test(this.email);
    },
    invalidEmailFeedback() {
      if (this.email.length === 0) {
        return "";
      }

      if (this.emailState) {
        this.$emit("emailValid");
        return "";
      } else {
        this.$emit("emailInvalid");
        return this.$t("messages.invalidEmail");
      }
    },
    email: {
      get: function () {
        return this.$store.state.userDetails.email;
      },
      set: function (email) {
        this.$store.commit("SET_USER_EMAIL", email);

        if (this.emailState) {
          this.$emit("emailEntered");
        }
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
