export default {
  baseApiUrl: (state) => state.baseApiUrl,
  stubbedApiUrl: (state) => state.stubbedApiUrl,
  locale: (state) => state.locale,
  sessionId: (state) => state.sessionId,
  availableDates: (state) => state.availableDates,
  availableTimes: (state) => state.availableTimes,
  selectedDate: (state) => state.selectedDate,
  selectedTime: (state) => state.selectedTime,
  quickSearchSelectedStartTime: (state) => state.quickSearchSelectedStartTime,
  quickSearchSelectedNoOfGolfers: (state) =>
    state.quickSearchSelectedNoOfGolfers,
  quickSearchSelectedCourse: (state) => state.quickSearchSelectedCourse,

  currentReservations: (state) => state.currentReservations,
  cancelingReservation: (state) => state.cancelingReservation,
  fetchingReservations: (state) => state.fetchingReservations,
  reservationDetails: (state) => state.reservationDetails,
  selectedNumGolfers: (state) => state.reservationSelectedNumGolfers,
  reservationMemberCardType: (state) => state.reservationMemberCardType,
  reservationFirstName: (state) => state.reservationFirstName,
  reservationLastName: (state) => state.reservationLastName,
  confirmingReservation: (state) => state.confirmingReservation,
  confirmedReservation: (state) => state.reservationConfirmed,

  loginInProcess: (state) => state.loginInProcess,
  userLoggedIn: (state) => state.userDetails.loggedIn,

  userExistsForEmail: (state) => state.userExistsForEmail,
  creatingAccountInProcess: (state) => state.creatingAccountInProcess,
  userEmail: (state) => state.userDetails.email,
  userFirstName: (state) => state.userDetails.firstName,
  userLastName: (state) => state.userDetails.lastName,
  userPostalCode: (state) => state.userDetails.postalCode,
  userAddress: (state) => state.userDetails.address,
  userAddress2: (state) => state.userDetails.address2,
  userCountry: (state) => state.userDetails.country,
  userProvince: (state) => state.userDetails.province,
  userCity: (state) => state.userDetails.city,
  userTelephone: (state) => state.userDetails.telephone,
  userNewsletterSignup: (state) => state.userDetails.newsletterSignup,
  userPasswordToken: (state) => state.userDetails.passwordToken,
  userPassword: (state) => state.userDetails.password,
  userPasswordConfirmation: (state) => state.userDetails.passwordConfirmation,
  userLoginErrorMsg: (state) => state.userDetails.userLoginErrorMsg,
  passwordResetInProcess: (state) => state.userDetails.passwordResetInProcess,
  passwordResetSent: (state) => state.userDetails.passwordResetSent,
  passwordWasReset: (state) => state.userDetails.passwordWasReset,
  passwordResetFailed: (state) => state.userDetails.passwordResetFailed,

  creditCardNumber: (state) => state.checkoutDetails.creditCardNumber,
  creditCardName: (state) => state.checkoutDetails.creditCardName,
  creditCardExpirationDate: (state) =>
    `${state.checkoutDetails.creditCardExpirationMonth}/${state.checkoutDetails.creditCardExpirationYear}`,
  creditCardExpirationMonth: (state) =>
    state.checkoutDetails.creditCardExpirationMonth,
  creditCardExpirationYear: (state) =>
    state.checkoutDetails.creditCardExpirationYear,
};
