<template>
  <div>
    <b-form-group
      :label="inputLabel"
      :label-for="inputID"
      :label-cols="labelCols"
      :content-cols="inputCols"
      label-size="sm"
      :invalid-feedback="invalidValueText"
      :state="inputState"
    >
      <b-form-input
        size="sm"
        :id="inputID"
        v-model="inputValue"
        :state="inputState"
        debounce="1500"
        :required="inputRequired"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  // This input just names itself what is passed in and validates length >= 2
  name: "CcNumber",
  props: {
    inputName: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    inputRequired: {
      type: Boolean,
      default: false,
    },
    labelCols: {
      type: String,
      default: "4",
    },
    inputCols: {
      type: String,
      default: "8",
    },
    numbersOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    inputID() {
      return `${this.inputName}-id`;
    },
    inputState() {
      if (this.inputValue.length === 0) {
        return;
      }
      let ccValue = this.inputValue;

      return this.luhnChk(ccValue);
    },
    inputValue: {
      get: function () {
        return (
          this.$store.state[this.inputName] ||
          this.$store.state["userDetails"][this.inputName] ||
          this.$store.state["checkoutDetails"][this.inputName] ||
          ""
        );
      },
      set: function (val) {
        this.$store.commit(`SET_${this.inputName.toUpperCase()}_VALUE`, val);
      },
    },
    invalidValueText() {
      if (this.inputValue.length === 0) {
        return "";
      }

      if (this.numbersOnly && /[\D]/.test(this.inputValue)) {
        return this.$t("messages.forms.numbersOnly");
      }

      if (this.inputValue.length >= 2) {
        return "";
      } else {
        return this.$t("messages.forms.inputTooShort");
      }
    },
  },
  methods: {
    luhnChk: function (value) {
      // Accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false;

      // The Luhn Algorithm. It's so pretty.
      let nCheck = 0;
      value = value.replace(/\D/g, "");

      for (let n = 0; n < value.length; n++) {
        let nDigit = parseInt(value[n], 10);

        if (!((n + value.length) % 2) && (nDigit *= 2) > 9) nDigit -= 9;

        nCheck += nDigit;
      }

      return nCheck % 10 === 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
