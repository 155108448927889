<template>
  <div>
    <b-form-group
      :label="inputLabel"
      :label-for="inputID"
      :label-cols="labelCols"
      :content-cols="inputCols"
      label-size="sm"
      :invalid-feedback="invalidValueText"
      :state="inputState"
    >
      <b-form-input
        size="sm"
        :id="inputID"
        v-model="inputValue"
        :state="inputState"
        debounce="1500"
        :required="inputRequired"
        tel
        lazy
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "TelephoneInput",
  props: {
    inputName: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    inputRequired: {
      type: Boolean,
      default: false,
    },
    labelCols: {
      type: String,
      default: "4",
    },
    inputCols: {
      type: String,
      default: "8",
    },
  },
  data() {
    return {};
  },
  computed: {
    inputID() {
      return `${this.inputName}-id`;
    },
    inputState() {
      if (this.inputValue.length === 0) {
        return;
      }

      return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        this.inputValue
      );
    },
    inputValue: {
      get: function () {
        return (
          this.$store.state[this.inputName] ||
          this.$store.state["userDetails"][this.inputName] ||
          ""
        );
      },
      set: function (val) {
        this.$store.commit(`SET_${this.inputName.toUpperCase()}_VALUE`, val);
      },
    },
    invalidValueText() {
      if (this.inputValue.length === 0) {
        return "";
      }

      if (
        !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          this.inputValue
        )
      ) {
        return this.$t("messages.forms.invalidPhoneNumber");
      } else {
        return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
