<template>
  <div>
    <b-form-group
      :label="$t('labels.lastName')"
      label-for="lastNameLogin"
      label-cols-sm="4"
      label-size="sm"
      :invalid-feedback="invalidlastNameFeedback"
      :state="lastNameState"
    >
      <b-form-input
        id="lastNameLogin"
        size="sm"
        v-model="lastName"
        :state="lastNameState"
        trim
        debounce="1000"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "LastNameInput",
  computed: {
    lastName: {
      get: function () {
        return this.$store.state.userDetails.lastName || "";
      },
      set: function (val) {
        this.$store.commit(`SET_LAST_NAME_VALUE`, val);
      },
    },
    lastNameState() {
      if (this.lastName.length === 0) {
        return;
      }

      return this.lastName.length >= 2;
    },
    invalidlastNameFeedback() {
      if (this.lastName.length === 0) {
        return "";
      }

      if (this.lastNameState) {
        return "";
      } else {
        return "Enter at least 2 characters.";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
