<template>
  <div class="available-dates mt-4 mb-4">
    <h5 class="text-primary mb-1">{{ $t("titles.times") }}</h5>
    <b-list-group>
      <AvailabilityRow
        v-for="availableTime in filteredAvailableTimes"
        :available-time="availableTime"
        :key="`${availableTime.TeeTimeTime} - ${availableTime.TeetimeCourse}`"
      />
    </b-list-group>
    <div v-if="noTimesAvailable" class="text-warning font-weight-bold">
      {{ $t("messages.noAvailableTimes") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AvailabilityRow from "./calendar/AvailabilityRow.vue";

export default {
  name: "availableTimes",
  props: ["availableTimes"],
  components: {
    AvailabilityRow,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "quickSearchSelectedStartTime",
      "quickSearchSelectedNoOfGolfers",
      "quickSearchSelectedCourse",
    ]),
    noTimesAvailable() {
      return (
        this.availableTimes.length === 0 ||
        this.filteredAvailableTimes.length === 0
      );
    },
    filteredAvailableTimes() {
      const qsSelectedStartTime = this.quickSearchSelectedStartTime;
      const qsSelectedNoOfGolfers = this.quickSearchSelectedNoOfGolfers;
      const qsSelectedCourse = this.quickSearchSelectedCourse;
      return this.availableTimes.filter(function (availableTime) {
        const availHoursAndMins = availableTime.TeeTimeTime.split(":");
        const availHours = availHoursAndMins[0] * 1;
        const availMins = availHoursAndMins[1] * 1;

        const selectedHoursAndMins = qsSelectedStartTime.split(":");
        const selectedHours = selectedHoursAndMins[0] * 1;
        const selectedMins = selectedHoursAndMins[1] * 1;

        if (selectedHours > availHours) {
          return false;
        }

        // Hide with same hour but minutes are more than selected
        if (selectedHours === availHours && selectedMins > availMins) {
          return false;
        }

        if (
          qsSelectedNoOfGolfers &&
          availableTime.TeeTimeAvailableSpots < qsSelectedNoOfGolfers
        ) {
          return false;
        }

        if (
          qsSelectedCourse &&
          availableTime.TeetimeCourse !== qsSelectedCourse
        ) {
          return false;
        }

        return availableTime.TeeTimeAvailableSpots * 1 > 0;
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
