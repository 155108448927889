<template>
  <b-list-group-item
    v-if="showAvailablityRow"
    :variant="rowVariant"
    @click.prevent="setSelectedTime"
  >
    <div class="container">
      <div class="availability-row row">
        <div class="col-12 justify-content-between d-flex mt-1">
          <span class="start-time ml-1 mr-1 fs-6">
            {{ availableTime.TeeTimeTime }}
          </span>

          <span class="cost mr-1">
            {{ availableTime.TeeTimePrice }}
            $
          </span>
          <span class="number-golfers mr-2">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 528 250"
              style="enable-background: new 0 0 528 250"
              class="user-icon-svg"
              xml:space="preserve"
            >
              <path
                class="st1"
                d="M436.9,109.4c26.9,0,48.6-21.8,48.6-48.6s-21.8-48.6-48.6-48.6c-26.9,0-48.6,21.8-48.6,48.6
                S410,109.4,436.9,109.4L436.9,109.4z M457.5,112.7h-41.2c-34.3,0-62.2,27.9-62.2,62.3v50.4l0.1,0.8l3.5,1.1
                c32.8,10.2,61.2,13.7,84.6,13.7c45.8,0,72.3-13,73.9-13.9l3.2-1.6h0.3v-50.4C519.8,140.6,491.8,112.7,457.5,112.7L457.5,112.7z"
              />
            </svg>
            {{ availableTime.TeeTimeAvailableSpots }}
          </span>

          <span class="course-logo">
            <img
              :src="require(`@/assets/images/${courseImgName}`)"
              :alt="`${availableTime.TeetimeCourse} Logo`"
              class="d-none"
            />
            <img
              :src="`https://golf.tremblant.ca/img/${courseImgName}`"
              :alt="`${availableTime.TeetimeCourse} Logo`"
            />
          </span>
          <span class="course-name">
            {{ availableTime.TeetimeCourse }}
          </span>
        </div>
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AvailabilityRow",
  props: ["availableTime"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["selectedTime"]),
    showAvailablityRow() {
      if (!this.availableTime && !this.quickSearchSelectedStartTime) {
        return false;
      }

      return true;
    },
    rowVariant() {
      if (
        this.availableTime.TeeTimeTime === this.selectedTime.TeeTimeTime &&
        this.availableTime.TeetimeCourse === this.selectedTime.TeetimeCourse
      ) {
        return "success";
      }

      return "light";
    },
    courseImgName() {
      if (/Le Diable/i.test(this.availableTime.TeetimeCourse)) {
        return "logo-le-diable-sm-logo.png";
      } else {
        return "logo-le-geant-sm-logo.png";
      }
    },
  },
  methods: {
    ...mapActions(["removeTeeTimeFromCart"]),
    selectDate(date) {
      return date;
    },
    setSelectedTime() {
      if (this.selectedTime === this.availableTime) {
        this.removeTeeTimeFromCart();
        this.$store.commit("SET_SELECTED_TIME", "");
      } else {
        this.$store.commit("SET_SELECTED_TIME", this.availableTime);
        const element = document.getElementById("reservation-details");
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss">
#igore-golf-app {
  .list-group-item {
    padding: 0.25rem 0.5rem 0.25rem 0 !important;
    .availability-row {
      cursor: pointer;
      span {
        color: #666;
      }
      .start-time {
        color: #2c444d;
      }
      span.number-golfers {
        display: inline-block;
        white-space: nowrap;
        width: 22px;
        .user-icon-svg {
          display: inline-block;
          float: right;
          height: auto;
          margin: 0 0;
          width: 30px;
          path {
            fill: #666666;
          }
        }
      }
      .course-logo {
        img {
          width: 40px;
          @media (min-width: 768px) {
            width: 50px;
          }
        }
      }
    }
    &.list-group-item-success {
      background-color: #2c444d;
      span,
      span.start-time {
        color: #fff;
      }
      span.number-golfers {
        .user-icon-svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
</style>
