export default {
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_AVAILABLE_DATES(state, dates) {
    state.availableDates = dates;
  },
  SET_AVAILABLE_TIMES(state, times) {
    state.availableTimes = times;
  },
  SET_CURRENT_RESERVATIONS(state, reservations) {
    state.currentReservations = reservations;
  },
  SET_SELECTED_DATE(state, date) {
    state.selectedDate = date;
  },
  SET_SELECTED_TIME(state, time) {
    if (state.selectedTime === "") {
      // Clear out any previously confirmed reservation flows
      state.reservationConfirmed = false;
    }
    state.selectedTime = time;
  },
  // From QuickSearch used to filter down show options
  // Reset the selected time to clear selection,
  // so that user has to redo selection to trigger reservation details
  SET_SELECTED_START_TIME(state, time) {
    state.selectedTime = "";
    state.quickSearchSelectedStartTime = time;
  },
  // QuickSearch
  SET_SELECTED_NUM_GOLFERS(state, numGolfers) {
    state.selectedTime = "";
    state.quickSearchSelectedNoOfGolfers = numGolfers;
    state.reservationSelectedNumGolfers = numGolfers;
  },
  SET_SELECTED_COURSE(state, course) {
    state.selectedTime = "";
    state.quickSearchSelectedCourse = course;
  },
  // Reservation Details
  SET_RESERVATION_MEMBER_CARD_TYPE(state, cardType) {
    state.reservationMemberCardType = cardType;
  },
  SET_RESERVATIONFIRSTNAME_VALUE(state, name) {
    state.reservationFirstName = name;
  },
  SET_RESERVATIONLASTNAME_VALUE(state, name) {
    state.reservationLastName = name;
  },
  SET_RESERVATION_DETAILS(state, reservationDetails) {
    state.reservationDetails = reservationDetails;
  },
  SET_RESERVATION_NUM_GOLFERS(state, numGolfers) {
    state.reservationSelectedNumGolfers = numGolfers;
  },
  SET_SESSION_ID(state, sessionId) {
    state.sessionId = sessionId;
    sessionStorage.setItem("igoreSession", sessionId);
  },
  UNSET_SESSION_ID(state) {
    state.sessionId = "";
    sessionStorage.setItem("igoreSession", "");
  },
  SET_USER_EMAIL(state, email) {
    state.userDetails.email = email;
  },
  HANDLE_USER_FIND_BY_EMAIL(state, response) {
    if (response.ResponseCode === "1") {
      state.userExistsForEmail = true;
    } else {
      state.userExistsForEmail = false;
    }
  },
  HANDLE_ZIPCODE(state, response) {
    const values = response.ResponseValues;
    state.userDetails.city = values["City"];
    state.userDetails.province = values["State"];
    state.userDetails.country = values["Country"];
  },
  SET_FORGOT_PASSWORD_TOKEN(state, passwordToken) {
    state.userDetails.passwordToken = passwordToken;
  },
  SET_USER_PASSWORD(state, password) {
    state.userDetails.password = password;
  },
  SET_USER_PASSWORD_CONFIRMATION(state, password) {
    state.userDetails.passwordConfirmation = password;
  },
  SET_POSTALCODE_VALUE(state, postalCode) {
    state.userDetails.postalCode = postalCode;
  },
  SET_FIRST_NAME_VALUE(state, firstName) {
    state.userDetails.firstName = firstName;
  },
  SET_LAST_NAME_VALUE(state, lastName) {
    state.userDetails.lastName = lastName;
  },
  SET_ADDRESS_VALUE(state, address) {
    state.userDetails.address = address;
  },
  SET_ADDRESS2_VALUE(state, address2) {
    state.userDetails.address2 = address2;
  },
  SET_CITY_VALUE(state, city) {
    state.userDetails.city = city;
  },
  SET_COUNTRY_VALUE(state, country) {
    state.userDetails.country = country;
  },
  SET_PROVINCE_VALUE(state, province) {
    state.userDetails.province = province;
  },
  SET_TELEPHONE_VALUE(state, telephone) {
    state.userDetails.telephone = telephone;
  },
  SET_NEWSLETTER_SIGNUP_VALUE(state, newsletterSignup) {
    state.userDetails.newsletterSignup = newsletterSignup;
  },
  INVALID_LOGIN(state, response) {
    state.userDetails.loggedIn = false;
    state.userDetails.password = "";
    state.userDetails.passwordConfirmation = "";
    state.userDetails.userLoginErrorMsg = true;
  },
  SET_LOGIN_IN_PROCESS(state, value) {
    state.loginInProcess = value;
  },
  SET_LOGGED_IN_USER_DETAILS(state, response) {
    state.userDetails.loggedIn = true;

    state.userDetails.password = "";
    state.userDetails.passwordConfirmation = "";
    state.userDetails.userLoginErrorMsg = false;
    if (state.reservationFirstName === "") {
      state.reservationFirstName = response.firstname;
    }
    if (state.reservationLastName === "") {
      state.reservationLastName = response.Lastname;
    }
    if (state.userDetails.firstName === "") {
      state.userDetails.firstName = response.firstname;
    }
    if (state.userDetails.lastName === "") {
      state.userDetails.lastName = response.Lastname;
    }
    state.sessionId = response.SessionID;

    // set sessionStorage items
    sessionStorage.setItem("igoreSession", state.sessionId);
    sessionStorage.setItem("igoreUserloggedIn", true);
    const userDetails = {
      email: state.userDetails.email,
      firstName: state.userDetails.firstName,
      lastName: state.userDetails.lastName,
      postalCode: state.userDetails.postalCode,
      address: state.userDetails.address,
      address2: state.userDetails.address2,
      country: state.userDetails.country,
      province: state.userDetails.province,
      city: state.userDetails.city,
      telephone: state.userDetails.telephone,
    };
    sessionStorage.setItem("igoreUserDetails", JSON.stringify(userDetails));
  },
  CLEAR_LOGGED_IN_USER_DETAILS(state, response) {
    state.userDetails.loggedIn = false;
    state.userDetails.email = "";
    state.userDetails.password = "";
    state.userDetails.passwordConfirmation = "";
    state.userDetails.userLoginErrorMsg = "";
    state.sessionId = "";
    sessionStorage.removeItem("igoreSession");
    sessionStorage.removeItem("igoreUserloggedIn");
    sessionStorage.removeItem("igoreUserDetails");
  },
  SET_CREATE_ACCOUNT_IN_PROCESS(state, value) {
    state.creatingAccountInProcess = value;
  },
  HANDLE_CREATE_USER(state, response) {
    if (response.ResponseCode === "0") {
      this.commit("SET_LOGGED_IN_USER_DETAILS", {
        SessionID: response.sessionid,
      });
    } else if (response.ResponseCode === "55") {
      alert(response.ResponseMessage);
    }
  },
  SET_PASSWORD_RESET_IN_PROCESS(state, value) {
    state.userDetails.passwordResetInProcess = value;
  },
  HANDLE_PASSWORD_RESET_SENT(state, response) {
    // This is when user has sent password reset email to self
    state.userDetails.passwordResetInProcess = false;

    if (response.ResponseCode === "0") {
      state.userDetails.passwordResetSent = true;
    } else {
      state.userDetails.passwordResetFailed = true;
    }
  },
  HANDLE_PASSWORD_RESET(state, response) {
    // This is when user has actually reset their PW
    const newUrl = new URL(location.href);
    state.userDetails.passwordResetInProcess = false;
    state.userDetails.passwordToken = "";
    state.userDetails.passwordResetSent = false; // just in case

    // Clear the token out to reset form to login state
    newUrl.searchParams.delete("token");
    window.history.replaceState({}, document.title, newUrl.href);

    if (response.ResponseCode === "0") {
      state.userDetails.passwordWasReset = true;
    } else {
      state.userDetails.passwordResetFailed = true;
    }
  },
  SET_CREDITCARDNUMBER_VALUE(state, number) {
    state.checkoutDetails.creditCardNumber = number;
  },
  SET_CREDITCARDNAME_VALUE(state, name) {
    state.checkoutDetails.creditCardName = name;
  },
  SET_CREDITCARD_EXPIRATION_MONTH_VALUE(state, mm) {
    state.checkoutDetails.creditCardExpirationMonth = mm;
  },
  SET_CREDITCARD_EXPIRATION_YEAR_VALUE(state, yy) {
    state.checkoutDetails.creditCardExpirationYear = yy;
  },
  SET_CONFIRMING_RESERVATION_IN_PROCESS(state, value) {
    state.confirmingReservation = value;
  },
  CONFIRM_RESERVATION_BOOKED(state, response) {
    if (response.ResponseCode === "0") {
      state.reservationConfirmed = true;
      state.confirmingReservation = false; // for loading spinner
      // Reset available rows to fresh so SET_SELECTED_TIME resets reservationConfirmed to false
      state.selectedTime = "";
    } else {
      alert(response.ResponseMessage);
    }
  },
  SET_FETCHING_RESERVATION_IN_PROCESS(state) {
    state.fetchingReservations = true;
  },
  SET_FETCHING_RESERVATION_FINISHED(state) {
    state.fetchingReservations = false;
  },
  SET_CANCEL_RESERVATION_IN_PROCESS(state, value) {
    state.cancelingReservation = value;
  },
  CONFIRM_RESERVATION_CANCELED(state, value) {
    state.cancelingReservation = null; // for loading spinner

    const elIndex = state.currentReservations.findIndex(
      (el) => el.TeeTimeNUmber === value
    );
    if (elIndex !== -1) {
      state.currentReservations.splice(elIndex, 1);
    }
  },
  API_FAILURE(state, error) {
    console.error(error);
    state.status =
      "Please refresh to try again. If this continues, please wait 5 minutes and try again.";
  },
};
