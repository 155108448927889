import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import { messages } from "./i18n/messages.js";
import { dateTimeFormats } from "./i18n/datetimeformats.js";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueI18n);

import "./assets/css/custom.scss";

let locale = store.state.locale;
let igoreApp = document.getElementById("igore_golf_app");
if (igoreApp.hasAttribute("data-locale")) {
  locale = igoreApp.dataset.locale;
}

let queryLocale = window.location.search.split("locale=")[1];
if (["fr", "en"].indexOf(queryLocale) >= 0) {
  locale = queryLocale;
}

const i18n = new VueI18n({
  locale: locale,
  messages, // set locale messages
  dateTimeFormats, // set date and time formats
});

// Make sure to commit back the override
store.commit("SET_LOCALE", locale);

new Vue({
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#igore_golf_app");
