// export default i18n
export const messages = {
  en: {
    Golfers: "golfers",
    titles: {
      times: "Select your tee time",
      quickSearch: "Quick Search",
      calendar: "Calendar",
      currentReservations: "Current Reservations",
      reservationDetails: "Reservation Details",
      accountOrder: "Account/Order Confirmation",
      checkout: "Checkout",
    },
    subtitles: {
      login: "Login",
      createAccount: "Create Account",
      signInFirst: "Please sign-in below to complete check out.",
      noCurrentReservations: "Please sign-in to view any current reservations.",
      confirmCancelation: "Confirm Cancelation",
      checkout: "Please login or create an account to checkout.",
      resetPasswordViaToken: "Please reset your password.",
    },
    footers: {
      reservationDetails: "Please select the days and hours from calendar.",
    },
    messages: {
      calendarRateNote:
        "Lowest available daily rates are listed above. <a href='https://www.tremblant.ca/tra/royalty' class='text-decoration-underline' target='_blank'>Royalty</a> and taxes extra.",
      invalidLogin: "Incorrect login, please try again.",
      invalidEmail: "Please enter a valid email.",
      invalidEmailPassword: "Please enter a valid email and password",
      atLeast4Characters: "Enter at least 4 characters",
      userExistsForEmail: "A user exists in our system for this email.",
      userExistsForEmail2:
        "Please go to the login tab and use 'forgot password' if you cannot login.",
      resetPassword: "Reset Password",
      forgotPassword: "A password reset link has been sent you by email.",
      passwordWasJustReset: "Your password has been reset, please login now.",
      passwordResetFailedMsg:
        "Password reset failed. Please try again or reach out to us.",
      nowSignedIn: "you are now signed in. Please finish checking out below.",
      signOut: "Sign out",
      noAvailableTimes: "No result for the selected date",
      confirmCancelation: "Please confirm you want to cancel",
      checkout: {
        voucher:
          "The pricing shows the regular rate. If you own a voucher or a card, show it at the pro shop upon registration and adjustments will be made.",
        creditCard:
          "No amount will be charged on your credit card at this time, the full amount is due on the day of your round. A no-show will be charged the full amount on its credit card.",
        confirmedReservation:
          "Thank you for confirming your reservation. A confirmation email will be sent to you.",
        monthYearError: "mm/yy format only.",
      },
      ok_btn_txt: "Cancel Reservation",
      cancel_btn_txt: "Keep Reservation",
      forms: {
        inputRequired: "This input is required.",
        inputTooShort: "Enter at least 2 characters.",
        invalidPhoneNumber: "Enter a valid phone number.",
        numbersOnly: "Enter only numbers.",
      },
    },
    labels: {
      numGolfers: "No. of Golfers",
      startsAt: "Starts At",
      selectDate: "Choose a date",
      newsletterSignup:
        "Receive the latest news and deals from Tremblant. I can unsubscribe at any time.",
      forgotPassword: "Forgot Password?",
      email: "Email:",
      password: "Password:",
      confirmPassword: "Retype Password:",
      postalCode: "Postal Code:",
      address: "Address:",
      address2: "Address 2:",
      country: "Country:",
      city: "City:",
      telephone: "Telephone:",
      departureTime: "Departure Time:",
      golfCourse: "Golf Course:",
      players: "Players:",
      memberCardType: "Membership/Voucher:",
      price: "Price",
      traRoyalty: "T.R.A. Royalty 3%:",
      gst: "G.S.T. 5%:",
      pst: "P.S.T. 9.975%:",
      firstName: "First Name:",
      lastName: "Last Name:",
      creditCard: "Credit Card:",
      nameOnCard: "Name on Card:",
      MM: "MM",
      YY: "YY",
      confirmReservation: "Confirm Reservation",
      cancelReservation: "Cancel",
    },
    selectNum: "Select #",
    selectTime: "Select a time",
    selectCourse: "Select a course",
    selectCardType: "Select Card Type",
    course: "Course",
    cardTypes: {
      none: "None",
      t_golf: "T-Golf",
      multi_round: "Multi-round",
      corporate_ticket: "Corporate ticket",
      golf_max: "Golf max",
    },
  },
  fr: {
    Golfers: "joueurs",
    titles: {
      times: "Heure de départ",
      quickSearch: "Recherche rapide",
      calendar: "Calendrier",
      currentReservations: "Réservations Existantes",
      reservationDetails: "Détails de la réservation",
      accountOrder: "Compte/confirmation de la commande",
      checkout: "Compléter la commande",
    },
    subtitles: {
      login: "Ouvrir une session",
      createAccount: "Créer un compte",
      signInFirst: "Veuillez vous connecter pour poursuivre",
      noCurrentReservations:
        "Veuillez vous connecter pour voir vos réservations existantes.",
      confirmCancelation: "Confirmer l'annulation",
      checkout: "Veuillez choisir un départ pour compléter",
      resetPasswordViaToken: "Veuillez réinitialiser votre mot de passe.",
    },
    footers: {
      reservationDetails:
        "Veuillez choisir la date et l’heure en utilisant le calendrier",
    },
    messages: {
      calendarRateNote:
        "Tarifs journaliers les plus bas disponibles présentés ci-dessus. <a href='https://www.tremblant.ca/fr/avt/redevance' class='text-decoration-underline' target='_blank'>Redevance</a> et taxes en sus.",
      invalidLogin: "Connexion incorrecte, veuillez réessayer.",
      invalidEmail: "Veuillez entrer une adresse valide.",
      invalidEmailPassword:
        "Veuillez entrer une adresse et un mot de passe valides.",
      atLeast4Characters: "Saisissez au moins 4 caractères",
      resetPassword: "Réinitialiser le mot de passe",
      userExistsForEmail: "Utilisateur existant",
      userExistsForEmail2:
        "Un utilisateur existe déjà dans notre système pour cette adresse courriel. Veuillez vous rendre dans l'onglet de connexion et utiliser mot de passe oublié si vous ne pouvez pas vous connecter.",
      forgotPassword:
        "Un lien pour réinitialiser votre mot de passe vous a été envoyé par email.",
      passwordWasJustReset:
        "Votre mot de passe a été réinitialisé, veuillez maintenant vous connecter.",
      passwordResetFailedMsg:
        "La réinitialisation du mot de passe a échoué. Veuillez réessayer ou nous contacter.",
      nowSignedIn:
        "vous êtes maintenant connecté. Veuillez terminer la réservation ci-dessous.",
      signOut: "Se déconnecter",
      noAvailableTimes: "Aucun résultat pour la date sélectionnée",
      confirmCancelation: "Veuillez confirmer votre demande d'annulation",
      checkout: {
        voucher:
          "La tarification indique le prix régulier. Si vous possédez un bon ou une carte, présentez-le sur place et des ajustements seront effectués.",
        creditCard:
          "Aucun montant ne sera prélevé sur votre carte de crédit pour le moment, le montant total est dû le jour de votre partie. Le montant total sera débité sur la carte de crédit pour une non présentation.",
        confirmedReservation:
          "Merci d'avoir confirmé votre réservation. Un email de confirmation vous sera envoyé.",
        monthYearError: "mm/aa seulement.",
      },
      ok_btn_txt: "Annuler votre réservation",
      cancel_btn_txt: "Conserver votre réservation",
      forms: {
        inputRequired: "Cette entrée est obligatoire.",
        inputTooShort: "Entrez au moins 2 caractères.",
        invalidPhoneNumber: "Entrez un numéro de téléphone valide.",
        numbersOnly: "Entrez uniquement des chiffres.",
      },
    },
    labels: {
      startsAt: "Débute à",
      numGolfers: "Nombre de golfeurs",
      selectDate: "Choisir la date",
      newsletterSignup:
        "Je souhaite recevoir les dernières nouvelles et les promotions de la part de Tremblant. Je peux me désabonner en tout temps.",
      forgotPassword: "Mot de passe oublié?",
      email: "Courriel:",
      password: "Mot de passe:",
      confirmPassword: "Confirmer le mot de passe:",
      postalCode: "Code postal:",
      address: "Adresse:",
      address2: "Adresse ligne 2:",
      country: "Pays:",
      city: "Ville:",
      telephone: "Téléphone:",
      departureTime: "Temps de départ:",
      golfCourse: "Parcours:",
      players: "Joueurs:",
      memberCardType: "Adhésion/coupon:",
      price: "Tarif",
      traRoyalty: "Redevance AVT 3%:",
      gst: "T.P.S 5%:",
      pst: "T.V.Q. 9,975 %:",
      firstName: "Prénom:",
      lastName: "Nom de famille:",
      creditCard: "Carte de crédit:",
      nameOnCard: "Nom sur la carte:",
      MM: "MM",
      YY: "AA",
      confirmReservation: "Confirmer la réservation",
      cancelReservation: "Annuler",
    },
    selectNum: "Choisir #",
    selectTime: "Choisir heure",
    selectCourse: "Choisir le parcours",
    selectCardType: "Choisir",
    course: "Parcours",
    cardTypes: {
      none: "Aucune",
      t_golf: "T-Golf",
      multi_round: "Multi-rondes",
      corporate_ticket: "Billet corporatif",
      golf_max: "Golf max",
    },
  },
};
