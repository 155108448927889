<template>
  <div class="login-container mt-3">
    <form action="#">
      <div class="error-msg-red" v-if="showEmptyInputsErrorMsg">
        {{ emptyInputAlerts }}
      </div>
      <div class="error-msg-red" v-if="showLoginErrorMsg">
        {{ $t("messages.invalidLogin") }}
      </div>

      <div v-if="!showforgotPassword">
        <div
          v-if="passwordWasReset()"
          class="font-weight-bold text-success mt-2 mb-2"
        >
          {{ $t("messages.passwordWasJustReset") }}
        </div>

        <EmailInput
          formContext="login"
          @emailValid="setEmailValid()"
          @emailInvalid="setEmailInvalid()"
        />
        <PasswordInput formContext="login" includeConfirmation="false" />

        <SubmitFormBtn
          :btn-text="$t('subtitles.login')"
          :loading="loginInProcess()"
          :disabled="submitDisabled"
          @submitClicked="loginBtnClicked"
        />
      </div>

      <p class="col-form-label-sm mb-0">
        <a
          href="#forgot-password"
          v-if="!showforgotPassword"
          @click.prevent="clickedForgotPassword"
        >
          {{ $t("labels.forgotPassword") }}
        </a>
      </p>

      <ForgotPassword v-if="showforgotPassword" />
    </form>
  </div>
</template>

<script>
import EmailInput from "./forms/EmailInput.vue";
import PasswordInput from "./forms/PasswordInput.vue";
import ForgotPassword from "./forms/ForgotPassword.vue";
import SubmitFormBtn from "./forms/SubmitFormBtn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    EmailInput,
    PasswordInput,
    ForgotPassword,
    SubmitFormBtn,
  },
  data() {
    return {
      emailValid: true,
      forgotPasswordClicked: false,
      emptyInputAlerts: "",
    };
  },
  mounted() {
    let params = new URLSearchParams(window.location.search);
    let token = params.get("token");

    this.$store.commit("SET_FORGOT_PASSWORD_TOKEN", token);
  },
  computed: {
    showLoginErrorMsg() {
      return this.userLoginErrorMsg();
    },
    showEmptyInputsErrorMsg() {
      return this.emptyInputAlerts !== "";
    },
    showforgotPassword() {
      return this.forgotPasswordClicked || this.resetPasswordTokenSet;
    },
    resetPasswordTokenSet() {
      return (
        this.userPasswordToken() !== null && this.userPasswordToken() !== ""
      );
    },
    submitDisabled() {
      return (
        this.emailValid === false ||
        this.userEmail() === "" ||
        this.userPassword() === ""
      );
    },
  },
  methods: {
    ...mapActions(["loginUser"]),
    ...mapGetters([
      "userEmail",
      "userPassword",
      "userLoginErrorMsg",
      "loginInProcess",
      "userPasswordToken",
      "passwordWasReset",
    ]),
    setEmailValid() {
      this.emailValid = true;
    },
    setEmailInvalid() {
      this.emailValid = false;
    },
    clickedForgotPassword() {
      this.forgotPasswordClicked = true;
    },
    loginBtnClicked(text) {
      if (this.userEmail() === "" || this.userPassword() === "") {
        this.emptyInputAlerts = this.$t("messages.invalidEmailPassword");
        return;
      }

      this.emptyInputAlerts = "";
      this.loginUser();
    },
  },
};
</script>

<style scoped lang="scss">
.error-msg-red {
  color: red;
}
</style>
