<template>
  <div class="submit-form-btn">
    <b-overlay
      :show="loading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100 text-right"
      @hidden="onHidden"
    >
      <b-button
        type="submit"
        variant="primary"
        :disabled="loading || disabled"
        ref="button"
        @click="btnClicked"
      >
        {{ btnText }}
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SubmitFormBtn",
  props: ["btnText", "disabled", "loading"],
  data() {
    return {};
  },
  methods: {
    btnClicked() {
      this.$emit("submitClicked");
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
