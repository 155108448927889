<template>
  <div class="forgot-password mt-2">
    <p
      class="font-weight-bold text-success text-primary"
      v-if="!firstPasswordReset"
    >
      {{ $t("subtitles.resetPasswordViaToken") }}
    </p>
    <EmailInput formContext="forgotPassword" />
    <PasswordInput
      formContext="forgotPassword"
      includeConfirmation="true"
      v-if="!firstPasswordReset"
    />
    <SubmitFormBtn
      :btn-text="$t('messages.resetPassword')"
      :loading="passwordResetInProcess"
      :disabled="false"
      @submitClicked="sendPasswordReset"
    />

    <div
      v-if="passwordResetSent"
      class="text-center font-weight-bold text-success mt-2"
    >
      {{ $t("messages.forgotPassword") }}
    </div>

    <div
      v-if="passwordResetFailed"
      class="text-center font-weight-bold text-danger mt-2"
    >
      {{ $t("messages.passwordResetFailedMsg") }}
    </div>
  </div>
</template>

<script>
import EmailInput from "./EmailInput.vue";
import PasswordInput from "./PasswordInput.vue";
import SubmitFormBtn from "./SubmitFormBtn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ForgotPassword",
  props: [],
  components: {
    EmailInput,
    PasswordInput,
    SubmitFormBtn,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "passwordResetInProcess",
      "passwordResetSent",
      "passwordResetFailed",
      "userPasswordToken",
    ]),
    firstPasswordReset() {
      return this.userPasswordToken === null || this.userPasswordToken === "";
    },
  },
  methods: {
    ...mapActions(["sendPasswordReset"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
