import axios from "axios";

export default {
  checkForExistingSession({ commit, state }) {
    if (sessionStorage.igoreSession) {
      state.sessionId = sessionStorage.getItem("igoreSession");
      state.userDetails.loggedIn = sessionStorage.igoreUserloggedIn;

      if (sessionStorage.igoreUserDetails) {
        const sessionUserDetails = JSON.parse(sessionStorage.igoreUserDetails);
        state.userDetails.email = sessionUserDetails.email;
        state.userDetails.firstName = sessionUserDetails.firstName;
        state.userDetails.lastName = sessionUserDetails.lastName;
        state.userDetails.postalCode = sessionUserDetails.postalCode;
        state.userDetails.address = sessionUserDetails.address;
        state.userDetails.address2 = sessionUserDetails.address2;
        state.userDetails.country = sessionUserDetails.country;
        state.userDetails.province = sessionUserDetails.province;
        state.userDetails.city = sessionUserDetails.city;
        state.userDetails.telephone = sessionUserDetails.telephone;
      }
    }
  },
  async getAvailableDates({ commit, getters }) {
    const datesUrl = `${getters.baseApiUrl}/MonthlyView`;

    try {
      const response = await axios.post(datesUrl, {
        action: "MonthlyView",
        parameters: {
          SessionID: getters.sessionId,
          MonthNumber: "8",
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_AVAILABLE_DATES", jsonResponse.ResponseValues.TeetimeDates);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async getAvailableTimes({ commit, getters }) {
    commit("SET_AVAILABLE_TIMES", null); // clear out to trigger loading
    const dailyTimesUrl = `${getters.baseApiUrl}/DailyView`;

    try {
      const response = await axios.post(dailyTimesUrl, {
        action: "DailyView",
        parameters: {
          SessionID: getters.sessionId,
          TeeTimeDate: getters.selectedDate,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_AVAILABLE_TIMES", jsonResponse.ResponseValues.TeetimeHours);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  //
  async removeTeeTimeFromCart({ commit, getters }) {
    const removeTeeTimeFromCartUrl = `${getters.baseApiUrl}/RemoveTeetime`;

    if (getters.sessionId === "") {
      return;
    }
    try {
      const response = await axios.post(removeTeeTimeFromCartUrl, {
        action: "RemoveTeetime",
        parameters: {
          SessionID: getters.sessionId,
        },
      });
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async clearReservationDetails({ commit, state }) {
    state.reservationSelectedNumGolfers = "";
    commit("SET_RESERVATION_DETAILS", {});
    commit("SET_SELECTED_TIME", "");
  },
  async addTeeTimeToCart({ commit, getters }) {
    const selectedDate = getters.selectedDate;
    const selectedTime = getters.selectedTime;
    const reservationDetailsUrl = `${getters.baseApiUrl}/AddTeeTime`;
    const selectedNumGolfers = getters.selectedNumGolfers;

    try {
      const response = await axios.post(reservationDetailsUrl, {
        action: "AddTeeTimeToCart",
        parameters: {
          SessionID: getters.sessionId,
          TeetimeHour: selectedTime.TeeTimeTime,
          TeetimeDate: selectedDate.replace("-", "").replace("-", ""),
          TeetimeCourse: selectedTime.TeetimeCourse,
          NumberOfSpots: selectedNumGolfers,
          TeeTimeProduct: selectedTime.TeeTimeProduct,
          ProductType: "",
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_RESERVATION_DETAILS", jsonResponse.ResponseValues);
      commit("SET_SESSION_ID", jsonResponse.ResponseValues.sessionid);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  // The only thing that updates right now is the selected # of golfers
  async updateReservationInCart({ commit, getters }) {
    const reservationUpdateUrl = `${getters.baseApiUrl}/ChangeNumberofSpots`;
    const selectedNumGolfers = getters.selectedNumGolfers;

    try {
      const response = await axios.post(reservationUpdateUrl, {
        action: "ChangeNumberofSpots",
        parameters: {
          SessionID: getters.sessionId,
          NumberOfSpots: selectedNumGolfers,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_RESERVATION_DETAILS", jsonResponse.ResponseValues);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async loginUser({ commit, getters }) {
    commit("SET_LOGIN_IN_PROCESS", true);
    const loginUrl = `${getters.baseApiUrl}/Login`;

    try {
      const response = await axios.post(loginUrl, {
        action: "Login",
        parameters: {
          SessionID: getters.sessionId,
          Username: getters.userEmail,
          Password: getters.userPassword,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_LOGIN_IN_PROCESS", false);
      if (jsonResponse.ResponseCode === "99") {
        commit("INVALID_LOGIN", jsonResponse.ResponseValues);
      } else {
        commit("SET_LOGGED_IN_USER_DETAILS", jsonResponse.ResponseValues);
      }
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async signOutUser({ commit, getters }) {
    const logoutUrl = `${getters.baseApiUrl}/Logout`;

    try {
      const response = await axios.post(logoutUrl, {
        action: "Logout",
        parameters: {
          SessionID: getters.sessionId,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("CLEAR_LOGGED_IN_USER_DETAILS", jsonResponse.ResponseValues);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async createAccount({ commit, dispatch, getters }) {
    commit("SET_CREATE_ACCOUNT_IN_PROCESS", true);
    const createAcctUrl = `${getters.baseApiUrl}/CreateCustomer`;

    try {
      const response = await axios.post(createAcctUrl, {
        action: "CreateCustomer",
        parameters: {
          SessionID: getters.sessionId,
          FirstName: getters.userFirstName,
          LastName: getters.userLastName,
          Email: getters.userEmail,
          Password: getters.userPassword,
          Address: getters.userAddress,
          Address2: getters.userAddress2,
          City: getters.userCity,
          ProvinceState: getters.userProvince,
          Country: getters.userCountry,
          PostalCode: getters.userPostalCode,
          Language: getters.locale,
          Phone: getters.userTelephone,
          NewsletterSignup: getters.userNewsletterSignup,
        },
      });
      commit("SET_CREATE_ACCOUNT_IN_PROCESS", false);
      const jsonResponse = JSON.parse(response.data);
      commit("HANDLE_CREATE_USER", jsonResponse);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async checkForExistingUserByEmail({ commit, getters }) {
    const emailCheckUrl = `${getters.baseApiUrl}/FindUserByEmail`;

    try {
      const response = await axios.post(emailCheckUrl, {
        action: "FindUserByEmail",
        parameters: {
          SessionID: getters.sessionId,
          Email: getters.userEmail,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("HANDLE_USER_FIND_BY_EMAIL", jsonResponse);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async getPostalCodeDetails({ commit, getters }) {
    const postalCodeUrl = `${getters.baseApiUrl}/FindCityFromPostalcode`;

    try {
      const response = await axios.post(postalCodeUrl, {
        action: "FindCityFromPostalcode",
        parameters: {
          SessionID: getters.sessionId,
          PostalCode: getters.userPostalCode,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("HANDLE_ZIPCODE", jsonResponse);
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async sendPasswordReset({ commit, getters }) {
    commit("SET_PASSWORD_RESET_IN_PROCESS", true);
    const postalCodeUrl = `${getters.baseApiUrl}/ResetPasswordV2`;
    const params = {
      Email: getters.userEmail,
    };
    // This is the actual reset of the PW
    const token = getters.userPasswordToken;
    const tokenSet = token !== "" && token !== null;
    if (tokenSet) {
      params.Password = getters.userPassword;
      params.token = token;
    }

    if (getters.userEmail === "") {
      alert("Please enter an email.");
      commit("SET_PASSWORD_RESET_IN_PROCESS", false);
      return;
    }

    try {
      const response = await axios.post(postalCodeUrl, {
        action: "ResetPassword",
        parameters: params,
      });
      const jsonResponse = JSON.parse(response.data);

      if (tokenSet) {
        // This means they have a pw reset token and have just reset their PW.
        commit("HANDLE_PASSWORD_RESET", jsonResponse);
      } else {
        commit("HANDLE_PASSWORD_RESET_SENT", jsonResponse);
      }
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async bookReservation({ commit, dispatch, getters, state }) {
    commit("SET_CONFIRMING_RESERVATION_IN_PROCESS", true);
    const bookReservationUrl = `${getters.baseApiUrl}/BookTeeTime`;
    const firstName = getters.reservationFirstName;
    const lastName = getters.reservationLastName;
    const memberCardType = getters.reservationMemberCardType;
    const locale = getters.locale;
    const creditCardNumber = getters.creditCardNumber;
    const creditCardName = getters.creditCardName;
    const creditCardExpirationDate = getters.creditCardExpirationDate;
    const teeTime = getters.selectedTime.TeeTimeTime;
    const selectedCourse = getters.selectedTime.TeetimeCourse;

    try {
      const response = await axios.post(bookReservationUrl, {
        action: "BookTeeTime",
        parameters: {
          SessionID: getters.sessionId,
          FirstName: firstName,
          LastName: lastName,
          MemberCardType: memberCardType,
          Language: locale,
          ChargeToAccount: "",
          CreditCardNumber: creditCardNumber,
          CreditCardName: creditCardName,
          CreditCardExpiration: creditCardExpirationDate,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("CONFIRM_RESERVATION_BOOKED", jsonResponse);

      if (jsonResponse.ResponseCode === "0") {
        const payload = {
          trasno: jsonResponse.ResponseValues.Transno,
          sku: `${getters.selectedDate} ${teeTime}`,
          selectedCourse: selectedCourse,
          price: getters.reservationDetails.Price,
          numGolfers: getters.selectedNumGolfers,
          resortTax: getters.reservationDetails.ResortTax,
          total: getters.reservationDetails.Total,
        };
        dispatch("setAnalyticsDetails", payload);
        setTimeout(function () {
          dispatch("getCurrentReservations");
        }, 2500);
      }
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  setAnalyticsDetails({ commit, getters }, payload) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      transactionId: payload.trasno, // Transaction ID. Required
      transactionAffiliation: "Tremblant Golf", // Affiliation or store name
      transactionTotal: payload.total, // Grand Total. Required
      transactionQuantity: 1, // DC Tag: Transaction Quantity.
      transactionTax: payload.resortTax, // Tax
      transactionShipping: 0.0, // Shipping
      event: "trackTrans", // Needed to fire GTM event
      transactionProducts: [
        {
          sku: payload.sku, // "2017-05-12 07:30:00", // Product Header Code, Quantity MUST be group by the SKU
          name: payload.selectedCourse, // Product Name
          category: "Golf Tee Time", // Product category for roll up
          price: payload.price, // unit price
          quantity: payload.numGolfers, // number of units
        },
      ],
    });
  },
  async getCurrentReservations({ commit, getters }) {
    commit("SET_FETCHING_RESERVATION_IN_PROCESS");
    const datesUrl = `${getters.baseApiUrl}/RTPTeetimes`;

    try {
      const response = await axios.post(datesUrl, {
        action: "RTPTeetimes",
        parameters: {
          SessionID: getters.sessionId,
        },
      });
      const jsonResponse = JSON.parse(response.data);
      commit("SET_CURRENT_RESERVATIONS", jsonResponse.ResponseValues.TeeTimes);
      commit("SET_FETCHING_RESERVATION_FINISHED");
    } catch (error) {
      commit("API_FAILURE", error);
    }
  },
  async cancelReservation({ commit, dispatch, getters, state }, bookingNumber) {
    commit("SET_CANCEL_RESERVATION_IN_PROCESS", bookingNumber);
    const cancelReservationUrl = `${getters.baseApiUrl}/CancelBooking`;

    try {
      const response = await axios.post(cancelReservationUrl, {
        action: "CancelBooking",
        parameters: {
          SessionID: getters.sessionId,
          BookingNumber: bookingNumber,
        },
      });
      const jsonResponse = JSON.parse(response.data);

      if (jsonResponse.ResponseCode === "0") {
        commit("CONFIRM_RESERVATION_CANCELED", bookingNumber);
      }
    } catch (error) {
      state.cancelingReservation = null;
      commit("API_FAILURE", error);
    }
  },
};
