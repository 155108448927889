<template>
  <div>
    <b-input-group :prepend="inputLabel" class="mb-2" variant="info">
      <b-form-input
        v-model="month"
        aria-label="Month"
        :placeholder="$t('labels.MM')"
        type="tel"
        lazy-formatter
        :formatter="monthFormatter"
        maxlength="2"
      >
      </b-form-input>
      <b-form-input
        v-model="year"
        aria-label="Year"
        :placeholder="$t('labels.YY')"
        lazy-formatter
        :formatter="yearFormatter"
        type="tel"
        maxlength="2"
      >
      </b-form-input>
    </b-input-group>
    <p v-if="monthError || yearError" class="text-danger">
      {{ $t("messages.checkout.monthYearError") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CcExpiration",
  props: {
    inputLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      monthError: false,
      yearError: false,
      maxlength: 2,
    };
  },
  computed: {
    month: {
      get: function () {
        return null;
      },
      set: function (val) {
        if (val.length < 1) {
          this.monthError = true;
        } else {
          this.monthError = false;
        }

        this.$store.commit("SET_CREDITCARD_EXPIRATION_MONTH_VALUE", val);
      },
    },
    year: {
      get: function () {
        return null;
      },
      set: function (val) {
        if (val.length < 1) {
          this.yearError = true;
          return;
        } else {
          this.yearError = false;
        }

        this.$store.commit("SET_CREDITCARD_EXPIRATION_YEAR_VALUE", val);
      },
    },
  },
  methods: {
    monthFormatter(value) {
      if (value > 12) {
        return null;
      }

      if (value.length < 2) {
        return `0${value}`;
      }
      return value.slice(0, this.maxlength);
    },
    yearFormatter(value) {
      if (value.length < 2) {
        return `0${value}`;
      }

      return value.slice(0, this.maxlength);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-group-text {
  background-color: transparent;
  border: none;
  font-size: 0.875rem;
  min-width: 113px;
  padding-left: 0;
  text-align: left;
}
.form-control {
  height: auto;
  padding: 0.25rem 0.5rem;
}
</style>
