<template>
  <div>
    <b-form-group
      :label="inputLabel"
      :label-for="inputID"
      :label-cols-sm="labelCols"
      :content-cols-sm="inputCols"
      label-size="sm"
      :invalid-feedback="invalidValue"
      :state="inputState"
    >
      <b-form-select
        size="sm"
        v-model="inputValue"
        :options="options"
        :id="inputID"
        class="w-100"
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  // This input just names itself what is passed in and validates length >= 2
  name: "SelectInput",
  props: {
    options: {
      type: Array,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    labelCols: {
      type: String,
      default: "4",
    },
    inputCols: {
      type: String,
      default: "8",
    },
  },
  data() {
    return {
      selectedValue: "",
    };
  },
  computed: {
    inputValue: {
      get: function () {
        return this.$store.state.userDetails[this.inputName] || "";
      },
      set: function (val) {
        this.$store.commit(`SET_${this.inputName.toUpperCase()}_VALUE`, val);
      },
    },
    inputID() {
      return `${this.inputName}-id`;
    },
    inputState() {
      if (this.inputValue.length === 0) {
        return;
      }

      return this.inputValue.length >= 2;
    },
    invalidValue() {
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
