<template>
  <div class="create-account-container mt-3">
    <form>
      <b-modal
        id="existing-user-modal"
        title="Existing User for Email"
        class="text-primary"
        ref="user-exists-modal"
        ok-only
        static
      >
        <p class="m-2">
          <strong>
            {{ $t("messages.userExistsForEmail") }}
          </strong>
        </p>
        <p class="m-2">
          {{ $t("messages.userExistsForEmail2") }}
        </p>
      </b-modal>

      <EmailInput
        formContext="createAccount"
        @emailValid="setEmailValid()"
        @emailInvalid="setEmailInvalid()"
        @emailEntered="checkForExistingUserByEmail()"
      />
      <PasswordInput formContext="createAccount" includeConfirmation="true" />

      <FirstNameInput />
      <LastNameInput />

      <DefaultInput
        :inputLabel="$t('labels.postalCode')"
        inputName="postalCode"
      />
      <DefaultInput :inputLabel="$t('labels.address')" inputName="address" />
      <DefaultInput :inputLabel="$t('labels.address2')" inputName="address2" />
      <SelectInput
        :inputLabel="$t('labels.country')"
        inputName="country"
        :options="countries"
      />
      <SelectInput
        inputLabel="Province:"
        inputName="province"
        :options="provincesStates"
      />
      <DefaultInput :inputLabel="$t('labels.city')" inputName="city" />
      <TelephoneInput
        :inputLabel="$t('labels.telephone')"
        inputName="telephone"
        :inputRequired="true"
      />

      <b-form-checkbox
        id="newsletter-signup"
        v-model="newsletterSignup"
        name="newsletter-signup"
        value="true"
        unchecked-value="false"
        size="sm"
        >{{ $t("labels.newsletterSignup") }}
      </b-form-checkbox>
      <br />

      <SubmitFormBtn
        :btn-text="$t('subtitles.createAccount')"
        :loading="creatingAccountInProcess"
        :disabled="submitDisabled"
        @submitClicked="createAccount"
      />
    </form>
  </div>
</template>

<script>
import EmailInput from "./forms/EmailInput.vue";
import PasswordInput from "./forms/PasswordInput.vue";
import FirstNameInput from "./forms/FirstNameInput.vue";
import LastNameInput from "./forms/LastNameInput.vue";
import DefaultInput from "./forms/DefaultInput.vue";
import TelephoneInput from "./forms/TelephoneInput.vue";
import SelectInput from "./forms/SelectInput.vue";
import SubmitFormBtn from "./forms/SubmitFormBtn.vue";
import { provincesStates } from "../i18n/provincesStates.js";
import { countries } from "../i18n/countries.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateAccount",
  components: {
    EmailInput,
    TelephoneInput,
    PasswordInput,
    FirstNameInput,
    LastNameInput,
    DefaultInput,
    SelectInput,
    SubmitFormBtn,
  },
  data() {
    return {
      anyInputInvalid: false,
      emailValid: true,
      provincesStates: provincesStates[this.$i18n.locale],
      countries: countries[this.$i18n.locale],
    };
  },
  computed: {
    emailState() {
      return this.userEmail.length > 4;
    },
    state() {
      return this.userEmail.length > 4;
    },
    invalidEmail() {
      if (this.userEmail.length > 0) {
        return this.$t("messages.atLeast4Characters");
      }
      return "";
    },
    invalidPassword() {
      if (this.password.length < 5) {
        return this.$t("messages.atLeast4Characters");
      }
      return "";
    },
    invalidPasswordConf() {
      if (this.password !== this.passwordConfirmation) {
        return "Passwords do not match";
      }
      return "";
    },
    newsletterSignup: {
      get: function () {
        return this.$store.state.userDetails.newsletterSignup || "";
      },
      set: function (val) {
        this.$store.commit("SET_NEWSLETTER_SIGNUP_VALUE", val);
      },
    },
    submitDisabled() {
      return (
        this.emailValid === false ||
        this.userEmail === "" ||
        this.userPassword === "" ||
        this.userPostalCode === "" ||
        this.userCity === "" ||
        this.userFirstName === "" ||
        this.userLastName === "" ||
        this.userAddress === "" ||
        this.userCountry === "" ||
        this.userProvince === "" ||
        this.userTelephone === ""
      );
    },
    ...mapGetters([
      "userLoggedIn",
      "userEmail",
      "userExistsForEmail",
      "userPostalCode",
      "userCity",
      "userFirstName",
      "userLastName",
      "userAddress",
      "userCountry",
      "userProvince",
      "userTelephone",
      "creatingAccountInProcess",
    ]),
  },
  methods: {
    setEmailValid() {
      this.emailValid = true;
    },
    setEmailInvalid() {
      this.emailValid = false;
    },
    ...mapActions([
      "checkForExistingUserByEmail",
      "getPostalCodeDetails",
      "createAccount",
    ]),
  },
  watch: {
    userExistsForEmail() {
      if (this.userExistsForEmail && !this.userLoggedIn) {
        this.$refs["user-exists-modal"].show();
      } else {
        this.$refs["user-exists-modal"].hide();
      }
    },
    userPostalCode() {
      if (this.userPostalCode.length < 5) {
        return;
      }
      this.getPostalCodeDetails();
    },
  },
};
</script>

<style lang="scss" scoped></style>
