<template>
  <div id="current-reservation" class="current-reservations mt-2">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="text-primary card-title">
          {{ $t("titles.currentReservations") }}
        </h5>

        <div class="card-footer bg-primary" v-if="!userLoggedIn">
          <p class="card-text text-white">
            <strong>{{ $t("subtitles.noCurrentReservations") }}</strong>
          </p>
        </div>
        <div v-else>
          <div class="refresh-reservations" @click="getCurrentReservations">
            <svg
              fill="#000000"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 367.136 367.136"
              xml:space="preserve"
            >
              <path
                d="M336.554,86.871c-11.975-18.584-27.145-34.707-44.706-47.731L330.801,0H217.436v113.91L270.4,60.691
              c40.142,28.131,65.042,74.724,65.042,124.571c0,83.744-68.13,151.874-151.874,151.874S31.694,269.005,31.694,185.262
              c0-58.641,32.781-111.009,85.551-136.669l-13.119-26.979C73.885,36.318,48.315,59.1,30.182,87.494
              c-18.637,29.184-28.488,62.991-28.488,97.768c0,100.286,81.588,181.874,181.874,181.874s181.874-81.588,181.874-181.874
              C365.442,150.223,355.453,116.201,336.554,86.871z"
              />
            </svg>
          </div>
          <b-overlay
            :show="fetchingReservations"
            opacity="1.0"
            spinner-small
            spinner-variant="primary"
          >
            <div></div>
          </b-overlay>
          <ul class="list-group overflow-auto">
            <TransitionGroup name="fade" appear>
              <li
                class="list-group-item"
                v-for="currentReservation in currentReservations"
                :key="`${currentReservation.TeeTimeNUmber} - ${currentReservation.TeeTimeDate}`"
              >
                <span>
                  {{ formattedTeeTimeDate(currentReservation.TeeTimeDate) }} -
                  {{ currentReservation.NumberOfSpots }}
                  {{ $t("Golfers") }}
                  ({{ currentReservation.TeeTimeCourse.replace(" - ", " ") }})
                </span>

                <a
                  href="#cancelReservation"
                  class="btn btn-warning btn-sm text-white float-right"
                  @click.prevent="confirmCancelReservation(currentReservation)"
                >
                  <b-overlay
                    :show="
                      cancelingReservation == currentReservation.TeeTimeNUmber
                    "
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline text-right"
                  >
                    <strong>
                      {{ $t("labels.cancelReservation") }}
                    </strong>
                  </b-overlay>
                </a>
              </li>
            </TransitionGroup>
          </ul>
          <b-modal
            :title="$t('subtitles.confirmCancelation')"
            id="confirm-cancel-modal"
            ref="confirm-cancel-modal"
            class="text-primary"
            static
            @ok="confirmedCancel"
          >
            <div class="m-2" v-if="cancelingReservationRef">
              <p class="text-warning font-weight-bold">
                {{ $t("messages.confirmCancelation") }}:
              </p>
              <p>
                {{ formattedTeeTimeDate(cancelingReservationRef.TeeTimeDate) }}
                -
                {{ cancelingReservationRef.NumberOfSpots }}
                {{ $t("Golfers") }}
                ({{ cancelingReservationRef.TeeTimeCourse }})
              </p>
            </div>
            <template #modal-footer="{ ok, cancel }">
              <b-button variant="secondary" @click="cancel()">
                {{ $t("messages.cancel_btn_txt") }}
              </b-button>
              <b-button variant="warning" @click="ok()">
                {{ $t("messages.ok_btn_txt") }}
              </b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CurrentReservations",
  components: {},
  props: ["userLoggedIn"],
  data() {
    return {
      cancelingReservationRef: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentReservations",
      "cancelingReservation",
      "fetchingReservations",
    ]),
  },
  methods: {
    ...mapActions(["getCurrentReservations", "cancelReservation"]),
    formattedTeeTimeDate(dateStr) {
      let date = new Date(dateStr);
      return this.$d(date.setDate(date.getDate()), "long");
    },
    confirmCancelReservation(reservation) {
      this.cancelingReservationRef = reservation;
      this.$refs["confirm-cancel-modal"].show();
    },
    confirmedCancel() {
      const TeeTimeNumber = this.cancelingReservationRef.TeeTimeNUmber;
      this.cancelReservation(TeeTimeNumber);
      this.cancelingReservationRef = null;
    },
  },
  watch: {
    userLoggedIn(userLoggedIn) {
      if (userLoggedIn) {
        this.getCurrentReservations();
      }
    },
  },
};
</script>

<style lang="scss">
#igore-golf-app {
  .current-reservations {
    position: relative;
    .refresh-reservations {
      cursor: pointer;
      position: absolute;
      height: 15px;
      right: 10px;
      top: 5px;
      width: 15px;
      svg {
        width: 100%;
      }
    }
    .card-body {
      padding: 0.5rem 0 0;
    }
    .card-title {
      padding: 0 0.5rem;
    }
    .list-group {
      padding: 0 1rem 1rem 1rem;
      max-height: 200px;
      .list-group-item {
        font-size: 0.9rem;
        height: auto;
        overflow: hidden;
        > span {
          display: inline-block;
          margin-top: 0.5rem;
        }
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
    .text-smaller {
      font-size: 0.7rem;
      margin-top: -5px;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
