<template>
  <div class="checkout mt-2">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="text-primary card-title">{{ $t("titles.checkout") }}</h5>

        <div class="card-footer bg-primary" v-if="noSelectedTime">
          <p class="card-text text-white">
            <strong>{{ $t("subtitles.checkout") }}</strong>
          </p>
        </div>
        <div v-else>
          <p class="m-2">{{ noCheckoutReason }}</p>

          <form v-if="showCheckoutForm">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <DefaultInput
                  label-cols="6"
                  input-cols="6"
                  :inputLabel="$t('labels.firstName')"
                  inputName="reservationFirstName"
                />
              </li>
              <li class="list-group-item">
                <DefaultInput
                  label-cols="6"
                  input-cols="6"
                  :inputLabel="$t('labels.lastName')"
                  inputName="reservationLastName"
                />
              </li>
              <li class="list-group-item mt-1">
                <b-form-group
                  :label="$t('labels.memberCardType')"
                  label-cols="6"
                  label-size="sm"
                  input-cols="6"
                  label-for="memberCardType"
                >
                  <b-form-select
                    v-model="memberCardType"
                    :options="memberCardTypeOptions"
                    id="memberCardType"
                    size="sm"
                    class="w-100"
                  ></b-form-select>
                </b-form-group>
                <p class="text-smaller">
                  <em>{{ $t("messages.checkout.voucher") }}</em>
                </p>
              </li>

              <li class="list-group-item">
                <CcNumber
                  :inputLabel="$t('labels.creditCard')"
                  inputName="creditCardNumber"
                />
              </li>
              <li class="list-group-item">
                <DefaultInput
                  :inputLabel="$t('labels.nameOnCard')"
                  inputName="creditCardName"
                />
              </li>
              <li class="list-group-item">
                <CcExpiration inputLabel="Expiration:" />
                <p class="text-smaller">
                  <em>{{ $t("messages.checkout.creditCard") }}</em>
                </p>
              </li>
              <li class="list-group-item mb-2">
                <SubmitFormBtn
                  :btn-text="$t('labels.confirmReservation')"
                  :loading="reservationInProcess"
                  @submitClicked="confirmReservation"
                />
              </li>
            </ul>
          </form>
        </div>
        <div
          v-if="confirmedReservation"
          class="m-2 font-weight-bold text-success"
        >
          {{ $t("messages.checkout.confirmedReservation") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "./forms/DefaultInput.vue";
import CcNumber from "./forms/CcNumber.vue";
import CcExpiration from "./forms/CcExpiration.vue";
import SubmitFormBtn from "./forms/SubmitFormBtn.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Checkout",
  components: {
    DefaultInput,
    CcNumber,
    CcExpiration,
    SubmitFormBtn,
  },
  props: ["selectedTime", "selectedDate", "userLoggedIn"],
  data() {
    return {
      memberCardTypeOptions: [
        { value: "none", text: this.$t("cardTypes.none") },
        { value: "t_golf", text: this.$t("cardTypes.t_golf") },
        { value: "multi_round", text: this.$t("cardTypes.multi_round") },
        {
          value: "corporate_ticket",
          text: this.$t("cardTypes.corporate_ticket"),
        },
        { value: "golf_max", text: this.$t("cardTypes.golf_max") },
      ],
    };
  },
  computed: {
    noSelectedTime() {
      return !this.selectedTime;
    },
    noCheckoutReason() {
      if (this.userLoggedIn) {
        return "";
      }

      return this.$t("subtitles.checkout");
    },
    reservationInProcess() {
      return this.confirmingReservation && !this.confirmedReservation;
    },
    memberCardType: {
      get: function () {
        return this.reservationMemberCardType;
      },
      set: function (num) {
        this.$store.commit("SET_RESERVATION_MEMBER_CARD_TYPE", num);
      },
    },
    showCheckoutForm() {
      return !this.confirmedReservation && this.userLoggedIn;
    },
    ...mapGetters([
      "reservationMemberCardType",
      "confirmingReservation",
      "confirmedReservation",
    ]),
  },
  methods: {
    ...mapActions(["bookReservation"]),
    readyToCheckout() {
      return false;
    },
    confirmReservation() {
      this.bookReservation();
    },
  },
};
</script>

<style lang="scss">
#igore-golf-app {
  .checkout {
    .card-body {
      padding: 0.5rem 0 0;
    }
    .card-title {
      padding: 0 0.5rem;
    }
    .list-group-flush {
      padding: 0 0.5rem;
      > .list-group-item {
        border-width: 0;
        padding: 0.25rem 0.5rem 0 0 !important;
      }
    }
    .text-smaller {
      font-size: 0.7rem;
      margin-top: -5px;
    }
  }
}
</style>
