// export default i18n
export const countries = {
  en: [
    { value: "Canada", text: "CANADA" },
    { value: "United States / Etats-Unis", text: "UNITED STATES" },
    { value: "Afghanistan", text: "Afghanistan" },
    { value: "Albania", text: "Albania" },
    { value: "Algeria", text: "Algeria" },
    { value: "Andorra", text: "Andorra" },
    { value: "Angola", text: "Angola" },
    { value: "Antigua and Barbuda", text: "Antigua and Barbuda" },
    { value: "Argentina", text: "Argentina" },
    { value: "Armenia", text: "Armenia" },
    { value: "Australia", text: "Australia" },
    { value: "Austria", text: "Austria" },
    { value: "Azerbaijan", text: "Azerbaijan" },
    { value: "Bahamas", text: "Bahamas" },
    { value: "Bahrain", text: "Bahrain" },
    { value: "Bangladesh", text: "Bangladesh" },
    { value: "Barbados", text: "Barbados" },
    { value: "Belarus", text: "Belarus" },
    { value: "Belgium", text: "Belgium" },
    { value: "Belize", text: "Belize" },
    { value: "Benin", text: "Benin" },
    { value: "Bhutan", text: "Bhutan" },
    { value: "Bolivia", text: "Bolivia" },
    { value: "Bosnia and Herzegovina", text: "Bosnia and Herzegovina" },
    { value: "Botswana", text: "Botswana" },
    { value: "Brazil", text: "Brazil" },
    { value: "Brunei", text: "Brunei" },
    { value: "Bulgaria", text: "Bulgaria" },
    { value: "Burkina Faso", text: "Burkina Faso" },
    { value: "Burundi", text: "Burundi" },
    { value: "Cabo Verde", text: "Cabo Verde" },
    { value: "Cambodia", text: "Cambodia" },
    { value: "Cameroon", text: "Cameroon" },
    { value: "Canada", text: "Canada" },
    { value: "Central African Republic", text: "Central African Republic" },
    { value: "Chad", text: "Chad" },
    { value: "Chile", text: "Chile" },
    { value: "China", text: "China" },
    { value: "Colombia", text: "Colombia" },
    { value: "Comoros", text: "Comoros" },
    {
      value: "Congo, Democratic Republic of the",
      text: "Congo, Democratic Republic of the",
    },
    { value: "Congo, Republic of the", text: "Congo, Republic of the" },
    { value: "Costa Rica", text: "Costa Rica" },
    { value: "Cote d'Ivoire", text: "Cote d'Ivoire" },
    { value: "Croatia", text: "Croatia" },
    { value: "Cuba", text: "Cuba" },
    { value: "Cyprus", text: "Cyprus" },
    { value: "Czechia", text: "Czechia" },
    { value: "Denmark", text: "Denmark" },
    { value: "Djibouti", text: "Djibouti" },
    { value: "Dominica", text: "Dominica" },
    { value: "Dominican Republic", text: "Dominican Republic" },
    { value: "Ecuador", text: "Ecuador" },
    { value: "Egypt", text: "Egypt" },
    { value: "El Salvador", text: "El Salvador" },
    { value: "Equatorial Guinea", text: "Equatorial Guinea" },
    { value: "Eritrea", text: "Eritrea" },
    { value: "Estonia", text: "Estonia" },
    { value: "Eswatini", text: "Eswatini" },
    { value: "Ethiopia", text: "Ethiopia" },
    { value: "Fiji", text: "Fiji" },
    { value: "Finland", text: "Finland" },
    { value: "France", text: "France" },
    { value: "Gabon", text: "Gabon" },
    { value: "Gambia", text: "Gambia" },
    { value: "Georgia", text: "Georgia" },
    { value: "Germany", text: "Germany" },
    { value: "Ghana", text: "Ghana" },
    { value: "Greece", text: "Greece" },
    { value: "Grenada", text: "Grenada" },
    { value: "Guatemala", text: "Guatemala" },
    { value: "Guinea", text: "Guinea" },
    { value: "Guinea-Bissau", text: "Guinea-Bissau" },
    { value: "Guyana", text: "Guyana" },
    { value: "Haiti", text: "Haiti" },
    { value: "Honduras", text: "Honduras" },
    { value: "Hungary", text: "Hungary" },
    { value: "Iceland", text: "Iceland" },
    { value: "India", text: "India" },
    { value: "Indonesia", text: "Indonesia" },
    { value: "Iran", text: "Iran" },
    { value: "Iraq", text: "Iraq" },
    { value: "Ireland", text: "Ireland" },
    { value: "Israel", text: "Israel" },
    { value: "Italy", text: "Italy" },
    { value: "Jamaica", text: "Jamaica" },
    { value: "Japan", text: "Japan" },
    { value: "Jordan", text: "Jordan" },
    { value: "Kazakhstan", text: "Kazakhstan" },
    { value: "Kenya", text: "Kenya" },
    { value: "Kiribati", text: "Kiribati" },
    { value: "Kosovo", text: "Kosovo" },
    { value: "Kuwait", text: "Kuwait" },
    { value: "Kyrgyzstan", text: "Kyrgyzstan" },
    { value: "Laos", text: "Laos" },
    { value: "Latvia", text: "Latvia" },
    { value: "Lebanon", text: "Lebanon" },
    { value: "Lesotho", text: "Lesotho" },
    { value: "Liberia", text: "Liberia" },
    { value: "Libya", text: "Libya" },
    { value: "Liechtenstein", text: "Liechtenstein" },
    { value: "Lithuania", text: "Lithuania" },
    { value: "Luxembourg", text: "Luxembourg" },
    { value: "Madagascar", text: "Madagascar" },
    { value: "Malawi", text: "Malawi" },
    { value: "Malaysia", text: "Malaysia" },
    { value: "Maldives", text: "Maldives" },
    { value: "Mali", text: "Mali" },
    { value: "Malta", text: "Malta" },
    { value: "Marshall Islands", text: "Marshall Islands" },
    { value: "Mauritania", text: "Mauritania" },
    { value: "Mauritius", text: "Mauritius" },
    { value: "Mexico", text: "Mexico" },
    { value: "Micronesia", text: "Micronesia" },
    { value: "Moldova", text: "Moldova" },
    { value: "Monaco", text: "Monaco" },
    { value: "Mongolia", text: "Mongolia" },
    { value: "Montenegro", text: "Montenegro" },
    { value: "Morocco", text: "Morocco" },
    { value: "Mozambique", text: "Mozambique" },
    { value: "Myanmar", text: "Myanmar" },
    { value: "Namibia", text: "Namibia" },
    { value: "Nauru", text: "Nauru" },
    { value: "Nepal", text: "Nepal" },
    { value: "Netherlands", text: "Netherlands" },
    { value: "New Zealand", text: "New Zealand" },
    { value: "Nicaragua", text: "Nicaragua" },
    { value: "Niger", text: "Niger" },
    { value: "Nigeria", text: "Nigeria" },
    { value: "North Korea", text: "North Korea" },
    { value: "North Macedonia", text: "North Macedonia" },
    { value: "Norway", text: "Norway" },
    { value: "Oman", text: "Oman" },
    { value: "Pakistan", text: "Pakistan" },
    { value: "Palau", text: "Palau" },
    { value: "Palestine", text: "Palestine" },
    { value: "Panama", text: "Panama" },
    { value: "Papua New Guinea", text: "Papua New Guinea" },
    { value: "Paraguay", text: "Paraguay" },
    { value: "Peru", text: "Peru" },
    { value: "Philippines", text: "Philippines" },
    { value: "Poland", text: "Poland" },
    { value: "Portugal", text: "Portugal" },
    { value: "Qatar", text: "Qatar" },
    { value: "Romania", text: "Romania" },
    { value: "Russia", text: "Russia" },
    { value: "Rwanda", text: "Rwanda" },
    { value: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", text: "Saint Lucia" },
    {
      value: "Saint Vincent and the Grenadines",
      text: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", text: "Samoa" },
    { value: "San Marino", text: "San Marino" },
    { value: "Sao Tome and Principe", text: "Sao Tome and Principe" },
    { value: "Saudi Arabia", text: "Saudi Arabia" },
    { value: "Senegal", text: "Senegal" },
    { value: "Serbia", text: "Serbia" },
    { value: "Seychelles", text: "Seychelles" },
    { value: "Sierra Leone", text: "Sierra Leone" },
    { value: "Singapore", text: "Singapore" },
    { value: "Slovakia", text: "Slovakia" },
    { value: "Slovenia", text: "Slovenia" },
    { value: "Solomon Islands", text: "Solomon Islands" },
    { value: "Somalia", text: "Somalia" },
    { value: "South Africa", text: "South Africa" },
    { value: "South Korea", text: "South Korea" },
    { value: "South Sudan", text: "South Sudan" },
    { value: "Spain", text: "Spain" },
    { value: "Sri Lanka", text: "Sri Lanka" },
    { value: "Sudan", text: "Sudan" },
    { value: "Suriname", text: "Suriname" },
    { value: "Sweden", text: "Sweden" },
    { value: "Switzerland", text: "Switzerland" },
    { value: "Syria", text: "Syria" },
    { value: "Taiwan", text: "Taiwan" },
    { value: "Tajikistan", text: "Tajikistan" },
    { value: "Tanzania", text: "Tanzania" },
    { value: "Thailand", text: "Thailand" },
    { value: "Timor-Leste", text: "Timor-Leste" },
    { value: "Togo", text: "Togo" },
    { value: "Tonga", text: "Tonga" },
    { value: "Trinidad and Tobago", text: "Trinidad and Tobago" },
    { value: "Tunisia", text: "Tunisia" },
    { value: "Turkey", text: "Turkey" },
    { value: "Turkmenistan", text: "Turkmenistan" },
    { value: "Tuvalu", text: "Tuvalu" },
    { value: "Uganda", text: "Uganda" },
    { value: "Ukraine", text: "Ukraine" },
    { value: "United Arab Emirates", text: "United Arab Emirates" },
    { value: "United Kingdom", text: "United Kingdom" },
    { value: "Uruguay", text: "Uruguay" },
    { value: "Uzbekistan", text: "Uzbekistan" },
    { value: "Vanuatu", text: "Vanuatu" },
    { value: "Vatican City", text: "Vatican City" },
    { value: "Venezuela", text: "Venezuela" },
    { value: "Vietnam", text: "Vietnam" },
    { value: "Yemen", text: "Yemen" },
    { value: "Zambia", text: "Zambia" },
    { value: "Zimbabwe", text: "Zimbabwe" },
  ],
  fr: [
    { value: "Canada", text: "CANADA" },
    { value: "United States / États-Unis", text: "ÉTATS UNIS" },
    { value: "L'Afghanistan", text: "L'Afghanistan" },
    { value: "Albanie", text: "Albanie" },
    { value: "Algérie", text: "Algérie" },
    { value: "Andorre", text: "Andorre" },
    { value: "L'Angola", text: "L'Angola" },
    { value: "Antigua-et-Barbuda", text: "Antigua-et-Barbuda" },
    { value: "Argentine", text: "Argentine" },
    { value: "Arménie", text: "Arménie" },
    { value: "Australie", text: "Australie" },
    { value: "L'Autriche", text: "L'Autriche" },
    { value: "Azerbaïdjan", text: "Azerbaïdjan" },
    { value: "Bahamas", text: "Bahamas" },
    { value: "Bahreïn", text: "Bahreïn" },
    { value: "Bangladesh", text: "Bangladesh" },
    { value: "Barbade", text: "Barbade" },
    { value: "Biélorussie", text: "Biélorussie" },
    { value: "Belgique", text: "Belgique" },
    { value: "Belize", text: "Belize" },
    { value: "Bénin", text: "Bénin" },
    { value: "Bhoutan", text: "Bhoutan" },
    { value: "Bolivie", text: "Bolivie" },
    { value: "Bosnie Herzégovine", text: "Bosnie Herzégovine" },
    { value: "Botswana", text: "Botswana" },
    { value: "Brésil", text: "Brésil" },
    { value: "Brunei", text: "Brunei" },
    { value: "Bulgarie", text: "Bulgarie" },
    { value: "Burkina Faso", text: "Burkina Faso" },
    { value: "Burundi", text: "Burundi" },
    { value: "Cabo Verde", text: "Cabo Verde" },
    { value: "Cambodge", text: "Cambodge" },
    { value: "Cameroun", text: "Cameroun" },
    { value: "Canada", text: "Canada" },
    { value: "République centrafricaine", text: "République centrafricaine" },
    { value: "Tchad", text: "Tchad" },
    { value: "Chili", text: "Chili" },
    { value: "Chine", text: "Chine" },
    { value: "Colombie", text: "Colombie" },
    { value: "Comores", text: "Comores" },
    {
      value: "Congo, République démocratique du",
      text: "Congo, République démocratique du",
    },
    { value: "Congo, République du", text: "Congo, République du" },
    { value: "Costa Rica", text: "Costa Rica" },
    { value: "Côte d'Ivoire", text: "Côte d'Ivoire" },
    { value: "Croatie", text: "Croatie" },
    { value: "Cuba", text: "Cuba" },
    { value: "Chypre", text: "Chypre" },
    { value: "Tchéquie", text: "Tchéquie" },
    { value: "Danemark", text: "Danemark" },
    { value: "Djibouti", text: "Djibouti" },
    { value: "Dominique", text: "Dominique" },
    { value: "République Dominicaine", text: "République Dominicaine" },
    { value: "Equateur", text: "Equateur" },
    { value: "Egypte", text: "Egypte" },
    { value: "Le Salvador", text: "Le Salvador" },
    { value: "Guinée Équatoriale", text: "Guinée Équatoriale" },
    { value: "Érythrée", text: "Érythrée" },
    { value: "Estonie", text: "Estonie" },
    { value: "Eswatini", text: "Eswatini" },
    { value: "Ethiopie", text: "Ethiopie" },
    { value: "Fidji", text: "Fidji" },
    { value: "Finlande", text: "Finlande" },
    { value: "France", text: "France" },
    { value: "Gabon", text: "Gabon" },
    { value: "Gambie", text: "Gambie" },
    { value: "Géorgie", text: "Géorgie" },
    { value: "Allemagne", text: "Allemagne" },
    { value: "Ghana", text: "Ghana" },
    { value: "Grèce", text: "Grèce" },
    { value: "Grenade", text: "Grenade" },
    { value: "Guatemala", text: "Guatemala" },
    { value: "Guinée", text: "Guinée" },
    { value: "Guinée-Bissau", text: "Guinée-Bissau" },
    { value: "Guyane", text: "Guyane" },
    { value: "Haïti", text: "Haïti" },
    { value: "Honduras", text: "Honduras" },
    { value: "Hongrie", text: "Hongrie" },
    { value: "Islande", text: "Islande" },
    { value: "Inde", text: "Inde" },
    { value: "Indonésie", text: "Indonésie" },
    { value: "L'Iran", text: "L'Iran" },
    { value: "Irak", text: "Irak" },
    { value: "Irlande", text: "Irlande" },
    { value: "Israël", text: "Israël" },
    { value: "Italie", text: "Italie" },
    { value: "Jamaïque", text: "Jamaïque" },
    { value: "Japon", text: "Japon" },
    { value: "Jordan", text: "Jordan" },
    { value: "Kazakhstan", text: "Kazakhstan" },
    { value: "Kenya", text: "Kenya" },
    { value: "Kiribati", text: "Kiribati" },
    { value: "Kosovo", text: "Kosovo" },
    { value: "Koweit", text: "Koweit" },
    { value: "Kirghizistan", text: "Kirghizistan" },
    { value: "Laos", text: "Laos" },
    { value: "Lettonie", text: "Lettonie" },
    { value: "Liban", text: "Liban" },
    { value: "Lesotho", text: "Lesotho" },
    { value: "Libéria", text: "Libéria" },
    { value: "Libye", text: "Libye" },
    { value: "Liechtenstein", text: "Liechtenstein" },
    { value: "Lituanie", text: "Lituanie" },
    { value: "Luxembourg", text: "Luxembourg" },
    { value: "Madagascar", text: "Madagascar" },
    { value: "Malawi", text: "Malawi" },
    { value: "Malaisie", text: "Malaisie" },
    { value: "Maldives", text: "Maldives" },
    { value: "Mali", text: "Mali" },
    { value: "Malte", text: "Malte" },
    { value: "Iles Marshall", text: "Iles Marshall" },
    { value: "Mauritanie", text: "Mauritanie" },
    { value: "Ile Maurice", text: "Ile Maurice" },
    { value: "Mexique", text: "Mexique" },
    { value: "Micronésie", text: "Micronésie" },
    { value: "Moldavie", text: "Moldavie" },
    { value: "Monaco", text: "Monaco" },
    { value: "Mongolie", text: "Mongolie" },
    { value: "Monténégro", text: "Monténégro" },
    { value: "Maroc", text: "Maroc" },
    { value: "Mozambique", text: "Mozambique" },
    { value: "Myanmar", text: "Myanmar" },
    { value: "Namibie", text: "Namibie" },
    { value: "Nauru", text: "Nauru" },
    { value: "Népal", text: "Népal" },
    { value: "Pays-Bas", text: "Pays-Bas" },
    { value: "Nouvelle-Zélande", text: "Nouvelle-Zélande" },
    { value: "Nicaragua", text: "Nicaragua" },
    { value: "Niger", text: "Niger" },
    { value: "Nigeria", text: "Nigeria" },
    { value: "Corée du Nord", text: "Corée du Nord" },
    { value: "Macédoine du Nord", text: "Macédoine du Nord" },
    { value: "Norvège", text: "Norvège" },
    { value: "Oman", text: "Oman" },
    { value: "Pakistan", text: "Pakistan" },
    { value: "Palau", text: "Palau" },
    { value: "Palestine", text: "Palestine" },
    { value: "Panama", text: "Panama" },
    { value: "Papouasie Nouvelle Guinée", text: "Papouasie Nouvelle Guinée" },
    { value: "Paraguay", text: "Paraguay" },
    { value: "Pérou", text: "Pérou" },
    { value: "Philippines", text: "Philippines" },
    { value: "Pologne", text: "Pologne" },
    { value: "le Portugal", text: "le Portugal" },
    { value: "Qatar", text: "Qatar" },
    { value: "Roumanie", text: "Roumanie" },
    { value: "Russie", text: "Russie" },
    { value: "Rwanda", text: "Rwanda" },
    { value: "Saint-Christophe-et-Niévès", text: "Saint-Christophe-et-Niévès" },
    { value: "Sainte-Lucie", text: "Sainte-Lucie" },
    {
      value: "Saint-Vincent-et-les-Grenadines",
      text: "Saint-Vincent-et-les-Grenadines",
    },
    { value: "Samoa", text: "Samoa" },
    { value: "Saint Marin", text: "Saint Marin" },
    { value: "Sao Tomé et Principe", text: "Sao Tomé et Principe" },
    { value: "Arabie Saoudite", text: "Arabie Saoudite" },
    { value: "Sénégal", text: "Sénégal" },
    { value: "Serbie", text: "Serbie" },
    { value: "les Seychelles", text: "les Seychelles" },
    { value: "Sierra Leone", text: "Sierra Leone" },
    { value: "Singapour", text: "Singapour" },
    { value: "Slovaquie", text: "Slovaquie" },
    { value: "Slovénie", text: "Slovénie" },
    { value: "Les îles Salomon", text: "Les îles Salomon" },
    { value: "Somalie", text: "Somalie" },
    { value: "Afrique du Sud", text: "Afrique du Sud" },
    { value: "Corée du Sud", text: "Corée du Sud" },
    { value: "Soudan du sud", text: "Soudan du sud" },
    { value: "Espagne", text: "Espagne" },
    { value: "Sri Lanka", text: "Sri Lanka" },
    { value: "Soudan", text: "Soudan" },
    { value: "Suriname", text: "Suriname" },
    { value: "Suède", text: "Suède" },
    { value: "la Suisse", text: "la Suisse" },
    { value: "Syrie", text: "Syrie" },
    { value: "Taïwan", text: "Taïwan" },
    { value: "Tadjikistan", text: "Tadjikistan" },
    { value: "Tanzanie", text: "Tanzanie" },
    { value: "Thaïlande", text: "Thaïlande" },
    { value: "Timor-Leste", text: "Timor-Leste" },
    { value: "Aller", text: "Aller" },
    { value: "Tonga", text: "Tonga" },
    { value: "Trinité-et-Tobago", text: "Trinité-et-Tobago" },
    { value: "Tunisie", text: "Tunisie" },
    { value: "dinde", text: "dinde" },
    { value: "Turkménistan", text: "Turkménistan" },
    { value: "Tuvalu", text: "Tuvalu" },
    { value: "Ouganda", text: "Ouganda" },
    { value: "Ukraine", text: "Ukraine" },
    { value: "Emirats Arabes Unis", text: "Emirats Arabes Unis" },
    { value: "Royaume-Uni", text: "Royaume-Uni" },
    { value: "Uruguay", text: "Uruguay" },
    { value: "Ouzbékistan", text: "Ouzbékistan" },
    { value: "Vanuatu", text: "Vanuatu" },
    { value: "Cité du Vatican", text: "Cité du Vatican" },
    { value: "Venezuela", text: "Venezuela" },
    { value: "Vietnam", text: "Vietnam" },
    { value: "Yémen", text: "Yémen" },
    { value: "Zambie", text: "Zambie" },
    { value: "Zimbabwe", text: "Zimbabwe" },
  ],
};
