<template>
  <div>
    <b-form-group
      :label="$t('labels.firstName')"
      label-for="firstNameLogin"
      label-cols-sm="4"
      label-size="sm"
      :invalid-feedback="invalidfirstName"
      :state="firstNameState"
    >
      <b-form-input
        id="firstNameLogin"
        size="sm"
        v-model="firstName"
        :state="firstNameState"
        trim
        debounce="1000"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "FirstNameInput",
  computed: {
    firstName: {
      get: function () {
        return this.$store.state.userDetails.firstName || "";
      },
      set: function (val) {
        this.$store.commit(`SET_FIRST_NAME_VALUE`, val);
      },
    },
    firstNameState() {
      if (this.firstName.length === 0) {
        return;
      }

      return this.firstName.length >= 2;
    },
    invalidfirstName() {
      if (this.firstName.length === 0) {
        return "";
      }

      if (this.firstNameState) {
        return "";
      } else {
        return "Enter at least 2 characters.";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
