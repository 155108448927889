<template>
  <div class="main-booking-calendar mb-2 mt-2">
    <b-calendar
      v-model="selectedDate"
      block
      @context="onContext"
      label-help=""
      :locale="locale"
      selected-variant="success"
      today-variant="info"
      nav-button-variant="primary"
      hide-header
      :initial-date="initialDate"
      :min="minDate"
      :max="maxDate"
    ></b-calendar>
    <div class="calendar-rate-note mt-1">
      <em v-html="$t('messages.calendarRateNote')"></em>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Calendar",
  props: ["availableDates"],
  data() {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const initialMonth = currentMonth >= 4 ? currentMonth : 4; // Show May unless past May
    const initialDate = new Date(currentYear, initialMonth, 1);
    const minDate = new Date(initialDate);
    const maxDate = new Date(currentYear, 9, 31);

    return {
      initialDate: initialDate,
      minDate: minDate,
      maxDate: maxDate,
    };
  },
  computed: {
    locale() {
      if (this.$i18n.locale == "fr") {
        return "fr-CA";
      } else {
        return "en-US";
      }
    },
    selectedDate: {
      get: function () {
        if (!this.$store.state.selectedDate) {
          return "";
        }
        return this.$store.state.selectedDate;
      },
      set: function (newDate) {
        // Clear out previously selected information
        this.removeTeeTimeFromCart();
        this.clearReservationDetails();
        this.$store.commit("SET_SELECTED_DATE", newDate);
      },
    },
  },
  methods: {
    ...mapActions(["removeTeeTimeFromCart", "clearReservationDetails"]),
    onContext(ctx) {
      // Have to wait for transition to finish between months
      let vue = this;
      setTimeout(function () {
        vue.populateAvailableDateInfo();
      }, 300);
    },
    populateAvailableDateInfo() {
      if (!this.availableDates) {
        return;
      }

      const locale = this.locale;

      this.availableDates.forEach(function (date) {
        const dateSpan = document.querySelector(
          `.b-calendar div[data-date="${date.TeeTimeDate}"] span`
        );

        if (dateSpan) {
          dateSpan.innerHTML = `<span class='date-circle'>${dateSpan.innerText}</span>`;

          const datePrice =
            locale === "en-US"
              ? `$${date.TeeTimeBestPrice}`
              : `${date.TeeTimeBestPrice} $`;

          dateSpan.setAttribute("data-best-price", datePrice);
          dateSpan.setAttribute(
            "data-availability",
            date.TeeTimeAvailableSpots
          );
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#igore-golf-app {
  .calendar-rate-note {
    font-size: 0.75rem;
  }
  .main-booking-calendar {
    .b-calendar {
      .b-calendar-nav {
        // embed page css creates the need for this due to 92px margin w/ high specificity
        margin-top: 0 !important;
        .btn.disabled:first-child,
        .btn.disabled:last-child {
          display: none;
        }
      }
      .b-calendar-grid-caption {
        color: #2c444d;
        font-family: "Stag";
        font-size: 1.1rem;
      }
      .b-calendar-grid-caption,
      .b-calendar-grid-weekdays {
        border-right: 1px solid #ced4da;
      }
      .b-calendar-grid-weekdays {
        // embed page css creates the need for this due to 92px margin w/ high specificity
        margin-top: 0 !important;
        small {
          font-size: 12.5px !important;
        }
      }
      .b-calendar-inner {
        margin-top: 0;
      }
      .b-calendar-grid {
        border-bottom: none;
        border-right: none;
        .b-calendar-grid-body {
          .row {
            .col[data-date] {
              border-bottom: 1px solid #ced4da;
              border-right: 1px solid #ced4da;
              span.btn {
                font-family: "Stag", serif;
                height: 60px;
                margin: 0;
                padding: 5px 0 3px 4px;
                text-align: left;
                position: relative;
                width: 100%;
                border-radius: 0px 0px !important;
                .date-circle {
                  background: #ccc;
                  display: inline-block;
                  color: #fff;
                  font-size: 12px;
                  height: 22px;
                  margin-top: -2px;
                  padding-top: 5px;
                  text-align: center;
                  width: 22px;
                  border-radius: 100%;
                }
                &::after,
                &::before {
                  font-family: "proxima-regular", sans-serif;
                  font-size: 12px;
                  left: 5px;
                  position: absolute;
                  width: 100%;
                }
                &::before {
                  background: url("~@/assets/images/svg/human-icon-tr-light-gray.svg")
                    100% 0 no-repeat;
                  content: attr(data-availability);
                  color: #aaaaaa;
                  top: 75%;
                  width: 75%;
                  @media (min-width: 768px) {
                    width: 55%;
                  }
                }
                &::after {
                  color: #2c444d;
                  content: attr(data-best-price);
                  padding-top: 4px;
                  top: 45%;
                  width: 80%;
                }
                &:focus,
                &.focus {
                  box-shadow: 0 0 0 0.1rem #9ab1ba;
                }
                &:hover {
                  background-color: #eee;
                }
                &.active {
                  background-color: #2c444d;
                  &:after {
                    color: #fff;
                  }
                  .date-circle {
                    background-color: #ab192d;
                  }
                }
              }
              span.btn.btn-outline-info {
                span.date-circle {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
